import { useCallback, useEffect, useMemo, useState } from 'react';
import { parse } from 'date-fns';
import { RootState } from 'store';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Permissions } from 'entities/access/Permissions';
import { AccessTypes, Roles } from 'entities/access/Access';
import { getPositionsByPriority } from 'features/game/utils';
import Player, { Foot, PlayerPositions } from 'entities/players/Player';
import { hasAccess, useOrgPermissions } from 'entities/access/access.utils';
import { userAccessRecordsSelector } from 'entities/access/access.selectors';
import { longlistsSelector } from 'entities/scouting-lists/scoutingLists.selectors';
import {
    fetchedPlayersIdsSelector,
    playersByIdsSelector,
} from 'entities/players/players.selectors';
import ScoutingList, {
    ListType,
    ScoutingListPlayerPositions,
} from 'entities/scouting-lists/ScoutingList';
import {
    Positions,
    ScoutingPosition,
    scoutingPositionsMap,
} from 'common/components/pitch/positionsCoordinates';
import {
    playersByIdsRequest,
    scoutingListPlayersByIdsRequest,
} from 'entities/players/players.actions';
import {
    organizationClubsInfoRequest,
    organizationPlayersInfoRequest,
} from 'entities/organizations/organizations.actions';
import {
    organizationClubsInfoSelector,
    organizationPlayersInfoSelector,
} from 'entities/organizations/organizations.selectors';
import { filterPlayers, PlayersFilters } from './search/filters.utils';

export const useScoutingAccess = ({
    organizationId,
    listId,
}: {
    organizationId: string;
    listId?: string;
}) => {
    const accessRecords = useSelector(userAccessRecordsSelector);
    const longlists = useSelector((state: RootState) =>
        longlistsSelector(state, { organizationId, listType: ListType.longlist }),
    );

    /* Roles */
    const isOrgAdmin = hasAccess(accessRecords, { organizationId }, AccessTypes.organization, [
        Roles.admin,
    ]);
    const isOrgScout = hasAccess(accessRecords, { organizationId }, AccessTypes.organization, [
        Roles.scout,
    ]);
    const isOrgIsolatedScout = hasAccess(
        accessRecords,
        { organizationId },
        AccessTypes.organization,
        [Roles.isolatedScout],
    );
    const isListScout = hasAccess(accessRecords, { organizationId, listId }, AccessTypes.longlist, [
        Roles.scout,
    ]);
    const isListIsolatedScout = hasAccess(
        accessRecords,
        { organizationId, listId },
        AccessTypes.longlist,
        [Roles.isolatedScout],
    );
    const isListClient = hasAccess(
        accessRecords,
        { organizationId, listId },
        AccessTypes.longlist,
        [Roles.reader],
    );

    /* Lists and players with access */
    const availableLonglists = accessRecords
        .filter((accessRecord) => accessRecord.accessType === AccessTypes.longlist)
        .map((accessRecord) => accessRecord.target.listId);
    const scoutingLonglists = accessRecords
        .filter(
            (accessRecord) =>
                accessRecord.accessType === AccessTypes.longlist &&
                Roles.scout === accessRecord.role,
        )
        .map((accessRecord) => accessRecord.target.listId);
    const isolatedScoutingLonglists = accessRecords
        .filter(
            (accessRecord) =>
                accessRecord.accessType === AccessTypes.longlist &&
                Roles.isolatedScout === accessRecord.role,
        )
        .map((accessRecord) => accessRecord.target.listId);
    const clientLonglists = accessRecords
        .filter(
            (accessRecord) =>
                accessRecord.accessType === AccessTypes.longlist &&
                Roles.reader === accessRecord.role,
        )
        .map((accessRecord) => accessRecord.target.listId);
    const playersToReadIds = longlists
        .filter((longlist) => availableLonglists.includes(longlist.id))
        .reduce((acc: string[], longlist) => {
            return acc.concat(Object.keys(longlist.players));
        }, []);
    const playersToEditIds = longlists
        .filter(
            (longlist) =>
                scoutingLonglists.includes(longlist.id) ||
                isolatedScoutingLonglists.includes(longlist.id),
        )
        .reduce((acc: string[], longlist) => {
            return acc.concat(Object.keys(longlist.players));
        }, []);

    /* Features */
    const hasAllLonglistsAccess = isOrgAdmin || isOrgScout;
    const canSeeAllPlayersReportsForListPosition = isListScout || isOrgAdmin || isOrgScout;
    const hasMatchReportsPageAccess =
        isOrgAdmin ||
        isOrgScout ||
        isOrgIsolatedScout ||
        scoutingLonglists.length > 0 ||
        isolatedScoutingLonglists.length > 0;
    const hasSearchPageAccess = isOrgAdmin || isOrgScout;
    const hasClubsPageAccess = isOrgAdmin || isOrgScout;
    const hasAgentsPageAccess = isOrgAdmin || isOrgScout;
    const hasRegionsPageAccess = isOrgAdmin || isOrgScout;
    const canReadAllPlayers = isOrgAdmin || isOrgScout || isOrgIsolatedScout;
    const canEditAllPlayers = isOrgAdmin || isOrgScout;
    const canReadAllPlayersReports = isOrgAdmin || isOrgScout;
    const canEditAllPlayersReports = isOrgAdmin || isOrgScout;
    const canReadAllMatchesReports = isOrgAdmin || isOrgScout;
    const canEditLonglistsFolders = isOrgAdmin;
    const canCreateLonglist = isOrgAdmin || isOrgScout;
    const canCreatePlayer =
        isOrgAdmin ||
        isOrgScout ||
        isOrgIsolatedScout ||
        isolatedScoutingLonglists.length > 0 ||
        scoutingLonglists.length > 0 ||
        clientLonglists.length > 0;
    const canReadPlayerGeneralInfo =
        isOrgAdmin ||
        isOrgScout ||
        isOrgIsolatedScout ||
        isolatedScoutingLonglists.length > 0 ||
        scoutingLonglists.length > 0 ||
        clientLonglists.length > 0;
    const canEditLonglist = isOrgAdmin || isOrgScout || isListScout || isListIsolatedScout;
    const canReadLonglist = canEditLonglist || isListClient || isOrgIsolatedScout;
    const canNotifyClients = isOrgAdmin || isOrgScout || isListScout;
    const canManageOrgAccess = isOrgAdmin;
    const canManageLonglistAccess = isOrgAdmin;
    const canEditPlayerLevelPotential = isOrgAdmin || isOrgScout;
    const canReadPlayersLevelPotential = isOrgAdmin || isOrgScout || isListScout;

    return {
        /* Features */
        hasAllLonglistsAccess,
        canSeeAllPlayersReportsForListPosition,
        hasMatchReportsPageAccess,
        hasSearchPageAccess,
        hasClubsPageAccess,
        hasAgentsPageAccess,
        hasRegionsPageAccess,
        canReadAllPlayers,
        canEditAllPlayers,
        canReadAllPlayersReports,
        canEditAllPlayersReports,
        canReadAllMatchesReports,
        canEditLonglistsFolders,
        canCreateLonglist,
        canCreatePlayer,
        canEditLonglist,
        canReadPlayerGeneralInfo,
        canReadLonglist,
        canNotifyClients,
        canManageOrgAccess,
        canManageLonglistAccess,
        canEditPlayerLevelPotential,
        canReadPlayersLevelPotential,

        /* Resourses */
        playersToReadIds,
        playersToEditIds,
        isolatedScoutingLonglists,
        scoutingLonglists,
        clientLonglists,
        availableLonglists,
    };
};

export const useScoutingPlayerAccess = ({
    organizationId,
    playerId,
}: {
    organizationId: string;
    playerId: string;
}) => {
    const {
        canReadAllPlayers,
        canEditAllPlayers,
        playersToReadIds,
        playersToEditIds,
        scoutingLonglists,
        isolatedScoutingLonglists,
        clientLonglists,
        canReadAllPlayersReports,
        canEditAllPlayersReports,
    } = useScoutingAccess({
        organizationId,
    });
    const longlists = useSelector((state: RootState) =>
        longlistsSelector(state, { organizationId, listType: ListType.longlist }),
    );
    const canViewPlayer = useMemo(() => {
        if (canReadAllPlayers) {
            return true;
        }

        /* Players ids from all available longlists: scout + reader (client) */
        return playersToReadIds.includes(playerId);
    }, [playersToReadIds.length, playerId, canReadAllPlayers]);

    const canEditPlayer = useMemo(() => {
        if (canEditAllPlayers) {
            return true;
        }

        /* Players ids from all available longlists with scouting role */
        return playersToEditIds.includes(playerId);
    }, [playersToEditIds.length, playerId, canEditAllPlayers]);

    const canSeeAllReports = useMemo(() => {
        if (canReadAllPlayersReports) {
            return true;
        }

        const longlistsWithReportsAccess = clientLonglists.concat(scoutingLonglists);

        /* Has at least one long list with target player and cheif scout access */
        return longlists.some(
            (longlist) =>
                Boolean(longlist.players[playerId]) &&
                longlistsWithReportsAccess.includes(longlist.id),
        );
    }, [longlists.length, playerId, canReadAllPlayersReports]);

    const canManageReports = useMemo(() => {
        if (canEditAllPlayersReports) {
            return true;
        }

        const longlistsWithReportsAccess = isolatedScoutingLonglists.concat(scoutingLonglists);

        /* Has at least one long list with target player and cheif scout access */
        return longlists.some(
            (longlist) =>
                Boolean(longlist.players[playerId]) &&
                longlistsWithReportsAccess.includes(longlist.id),
        );
    }, [longlists, playerId, canEditAllPlayersReports]);

    const hasFullPlayerAccess = useMemo(() => {
        if (canEditAllPlayers) {
            return true;
        }

        /* Has at least one long list with target player and cheif scout access */
        return longlists.some(
            (longlist) =>
                Boolean(longlist.players[playerId]) && scoutingLonglists.includes(longlist.id),
        );
    }, [longlists.length, playerId, canEditAllPlayers]);

    return {
        canViewPlayer,
        canEditPlayer,
        canDeletePlayer: hasFullPlayerAccess,
        canPlayer: hasFullPlayerAccess,
        canReadPlayerContractInfo: hasFullPlayerAccess,
        canEditPlayerContractInfo: hasFullPlayerAccess,
        canReadPlayerProfiling: hasFullPlayerAccess,
        canEditPlayerProfiling: hasFullPlayerAccess,
        canReadPlayerEvaluation: hasFullPlayerAccess,
        canEditPlayerEvaluation: hasFullPlayerAccess,
        canSeeAllReports,
        canManageReports,
    };
};

const colorsMap = new Map();
colorsMap.set(0.5, '#AAE5EC');
colorsMap.set(1, '#7CD8E2');
colorsMap.set(1.5, '#50C1D0');
colorsMap.set(2, '#28A4B1');
colorsMap.set(2.5, '#FFDA67');
colorsMap.set(3, '#FBBA02');
colorsMap.set(3.5, '#FC9600');
colorsMap.set(4, '#51CD6D');
colorsMap.set(4.5, '#04AB6A');
colorsMap.set(5, '#008650');
colorsMap.set(5.5, '#015836');

export const levelColorFunction = (value: number | number[]) => {
    const currentValue = Array.isArray(value) ? value[0] : value;

    return colorsMap.get(currentValue) || '#eee';
};

export type SearchPlayerInfo = Pick<
    Player,
    | 'dateOfBirth'
    | 'foot'
    | 'height'
    | 'countries'
    | 'profile'
    | 'quality'
    | 'potential'
    | 'positions'
> & {
    fullName: string;
    salary: number | null;
    expirationDate: Date | null;
    transferFee: number | null;
    references: string[];
    club: string[] | null;
    playerTeamsIds: Record<string, boolean>;
};

export const parsePlayerInfo = (playerHash: string): SearchPlayerInfo => {
    const [
        fullName, // 0
        dateOfBirthStr, // 1
        foot, // 2
        height, // 3
        positions, // 0
        countries, // 4
        profile, // 5
        quality, // 6
        potential, // 7
        salary, // 9
        expirationDateStr, // 10
        transferFee, // 11
        references, // 12
        club, // 13
        teamsIds = '', // 14
    ] = playerHash.split(';');
    const playerPositions = Object.fromEntries(
        positions.split(',').map((entry) => {
            const [pos, priority] = entry.split(':');
            return [pos, Number(priority)];
        }),
    );
    const playerTeamsIds = Object.fromEntries(
        teamsIds.split(',').map((entry) => {
            const [teamId, isActive] = entry.split(':');
            return [teamId, isActive === 'true'];
        }),
    );

    return {
        fullName,
        dateOfBirth: dateOfBirthStr ? parse(dateOfBirthStr, 'ddMMyyyy', new Date()) : new Date(),
        foot: (foot as Foot) || Foot.right,
        height: height ? Number(height) : null,
        positions: playerPositions,
        countries: countries.split(','),
        profile: profile.split(','),
        quality: quality ? Number(quality) : null,
        potential: potential ? Number(potential) : null,
        salary: salary ? Number(salary) : null,
        expirationDate: expirationDateStr ? parse(expirationDateStr, 'ddMMyyyy', new Date()) : null,
        transferFee: transferFee ? Number(transferFee) : null,
        references: references.split(','),
        club: club ? club.split(',') : null,
        playerTeamsIds,
    };
};

export type ClubInfo = {
    id: string;
    name: string;
    countryCode: string;
    filePath: string;
    references: string[];
    leagueReferences: string[];
    leagueId: string;
};

export const parseClubInfo = (clubHash: string): ClubInfo => {
    const [id, name, countryCode, filePath, references, leagueReferences, leagueId] =
        clubHash.split(';');

    return {
        id,
        name,
        countryCode,
        filePath,
        references: references.split(','),
        leagueReferences: leagueReferences.split(','),
        leagueId,
    };
};

export const getListsGroups = (longlists: ScoutingList[]) => {
    const allGroups = longlists.reduce((acc: string[], { groups }) => {
        return acc.concat(groups || []);
    }, []);

    return Array.from(new Set(allGroups));
};

/* Is used to request players for all scouting database */
export const useScoutingPlayersByIds = (organizationId: string) => {
    const dispatch = useDispatch();
    const hasOrgPermissions = useOrgPermissions({ organizationId });
    const [playersIds, setPlayersIds] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const playersList = useSelector((state: RootState) =>
        playersByIdsSelector(state, { playersIds }),
    );
    const fetchedPlayersIds = useSelector(fetchedPlayersIdsSelector);

    const handleRequestPlayers = useCallback(
        (playersIdsToRequest: string[]) => {
            setIsLoading(true);
            setPlayersIds(playersIdsToRequest);
            const fetchedIds = new Set(fetchedPlayersIds);
            const playersIdsToFetch = playersIdsToRequest.filter(
                (playerId) => !fetchedIds.has(playerId),
            );
            dispatch(
                playersByIdsRequest({
                    organizationId,
                    playersIds: playersIdsToFetch,
                    onSuccess: () => setIsLoading(false),
                    onFail: () => setIsLoading(false),
                }),
            );
        },
        [organizationId, fetchedPlayersIds.length, dispatch],
    );

    const result = useMemo(() => {
        const players = playersList.filter(({ id }) => playersIds.includes(id));
        /* Keep the order from playersIds */
        players.sort((a, b) => playersIds.indexOf(a.id) - playersIds.indexOf(b.id));

        return players;
    }, [playersIds, playersList]);

    const hasFullSeachAccess = hasOrgPermissions(Permissions.canReadScoutingPlayers);
    return {
        isLoading,
        playersList: result,
        playersIds,
        hasFullSeachAccess,
        onRequest: handleRequestPlayers,
    };
};

/* Is used to request players for specific longlistonly */
export const useLonglistPlayersByIds = ({
    listId,
    organizationId,
}: {
    organizationId: string;
    listId: string;
}) => {
    const dispatch = useDispatch();
    const [playersIds, setPlayersIds] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const playersList = useSelector((state: RootState) =>
        playersByIdsSelector(state, { playersIds }),
    );
    const fetchedPlayersIds = useSelector(fetchedPlayersIdsSelector);

    const handleRequestPlayers = useCallback(
        (playersIdsToRequest: string[]) => {
            setIsLoading(true);
            setPlayersIds(playersIdsToRequest);
            const fetchedIds = new Set(fetchedPlayersIds);
            const playersIdsToFetch = playersIdsToRequest.filter(
                (playerId) => !fetchedIds.has(playerId),
            );
            dispatch(
                scoutingListPlayersByIdsRequest({
                    listId,
                    organizationId,
                    playersIds: playersIdsToFetch,
                    onSuccess: () => setIsLoading(false),
                    onFail: () => setIsLoading(false),
                }),
            );
        },
        [listId, organizationId, fetchedPlayersIds.length, dispatch],
    );

    const result = useMemo(() => {
        const players = playersList.filter(({ id }) => playersIds.includes(id));
        /* Keep the order from playersIds */
        players.sort((a, b) => playersIds.indexOf(a.id) - playersIds.indexOf(b.id));

        return players;
    }, [playersIds, playersList]);

    return { isLoading, playersList: result, playersIds, onRequest: handleRequestPlayers };
};

const passOrgIds = ['9RRNAXYDt0Dcho0qkdFJ', '0vqF8ZRFhoUqEImUkTnK', 'YKRTPw2rfRe8YNXdXrbn'];

/* Allow the feature for PASS and UnderSports */
export const useIsPass = () => {
    const { organizationId } = useParams() as { organizationId: string };

    return passOrgIds.includes(organizationId);
};

const ntOrgIds = ['9RRNAXYDt0Dcho0qkdFJ', 'RnAl0WG9ewORO6tge4s9'];

/* Allow the feature for NT only */
export const useIsNT = () => {
    const { organizationId } = useParams() as { organizationId: string };

    return ntOrgIds.includes(organizationId);
};

export const useOrgScoutingPlayers = ({
    organizationId,
    filters,
    skipScouting,
}: {
    organizationId: string;
    filters?: PlayersFilters;
    skipScouting?: boolean;
}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const orgPlayersMap = useSelector((state: RootState) =>
        organizationPlayersInfoSelector(state, { organizationId }),
    );

    useEffect(() => {
        if (!orgPlayersMap) {
            setIsLoading(true);
            dispatch(
                organizationPlayersInfoRequest({
                    organizationId,
                    onSuccess: () => {
                        setIsLoading(false);
                    },
                    onFail: () => {
                        setIsLoading(false);
                    },
                }),
            );
        }
    }, [organizationId, orgPlayersMap, dispatch]);

    const playersInfoList = useMemo(() => {
        if (!orgPlayersMap) {
            return [];
        }

        return Object.entries(orgPlayersMap).map((entry) => {
            const [playerId, playerHash] = entry;

            return {
                id: playerId,
                ...parsePlayerInfo(playerHash),
            };
        });
    }, [orgPlayersMap]);

    const fiterPlayers = useCallback(
        (filtersToApply?: PlayersFilters) => {
            let result = playersInfoList;

            if (skipScouting) {
                result = result.filter(({ playerTeamsIds }) => {
                    const teamsIds = Object.keys(playerTeamsIds);
                    const isScoutingOnly = teamsIds.length === 1 && teamsIds[0] === 'scouting';
                    return !isScoutingOnly;
                });
            }

            return filtersToApply ? filterPlayers(result, filtersToApply) : result;
        },
        [playersInfoList, skipScouting],
    );

    const matchedPlayers = useMemo(() => fiterPlayers(filters), [fiterPlayers, filters]);

    return {
        isLoading,
        playersInfoList: matchedPlayers,
        fiterPlayers,
    };
};

export const useOrgClubs = (organizationId: string) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const orgClubsMap = useSelector((state: RootState) =>
        organizationClubsInfoSelector(state, { organizationId }),
    );

    useEffect(() => {
        if (!orgClubsMap) {
            setIsLoading(true);
            dispatch(
                organizationClubsInfoRequest({
                    organizationId,
                    onSuccess: () => {
                        setIsLoading(false);
                    },
                    onFail: () => {
                        setIsLoading(false);
                    },
                }),
            );
        }
    }, [organizationId, orgClubsMap, dispatch]);

    const clubsInfoList = useMemo(() => {
        if (!orgClubsMap) {
            return [];
        }

        return Object.values(orgClubsMap).map(parseClubInfo);
    }, [orgClubsMap]);

    return {
        isLoading,
        clubsInfoList,
    };
};

const getPlayerScoutingPositionsList = (positions: Positions[]) => {
    const scoutingPositionsList = positions
        .map((pos) => scoutingPositionsMap.get(pos))
        .filter(Boolean);
    return Array.from(new Set(scoutingPositionsList));
};

export const getInitialPositions = (positions: PlayerPositions) => {
    const primaryPositions = getPositionsByPriority(positions, 1);
    const scoutingPrimaryPositionsList = getPlayerScoutingPositionsList(primaryPositions);
    const secondaryPositions = getPositionsByPriority(positions, 2);
    const scoutingSecondaryPositionsList = getPlayerScoutingPositionsList(secondaryPositions);

    return {
        ...Object.fromEntries(scoutingPrimaryPositionsList.map((pos) => [pos, 1])),
        ...Object.fromEntries(scoutingSecondaryPositionsList.map((pos) => [pos, 2])),
    };
};

export const mapPlayerToScoutingPositions = (
    positions: PlayerPositions,
): ScoutingListPlayerPositions => {
    const scoutingPositionsList = Object.entries(positions)
        .map(([pos, priority]) => ({
            listPosition: scoutingPositionsMap.get(pos as Positions),
            priority,
        }))
        .filter(({ listPosition }) => listPosition)
        .reduce((acc: ScoutingListPlayerPositions, { listPosition, priority }) => {
            const prevValue = acc[listPosition as ScoutingPosition] || Infinity;

            return {
                ...acc,
                [listPosition as ScoutingPosition]: Math.min(priority, prevValue),
            };
        }, {} as ScoutingListPlayerPositions);

    return scoutingPositionsList;
};
