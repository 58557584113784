import { PlayerCharacteristic } from 'features/player/info/profile';
import { parsePlayerInfo } from '../scouting.utils';
import { ScoutingPosition, scoutingGroupsMap } from 'common/components/pitch/positionsCoordinates';
import { differenceInMonths, differenceInYears } from 'date-fns';

type NumbersRange = { min: number; max: number };

export const transferFeeValues = [
    0,
    10 ** 3,
    5 * 10 ** 3,
    10 ** 4,
    2 * 10 ** 4,
    3 * 10 ** 4,
    4 * 10 ** 4,
    5 * 10 ** 4,
    7 * 10 ** 4,
    10 ** 5,
    15 * 10 ** 4,
    2 * 10 ** 5,
    3 * 10 ** 5,
    4 * 10 ** 5,
    5 * 10 ** 5,
    10 ** 6,
    10 ** 7,
    10 ** 8,
];
export const salaryValues = [
    0,
    10 ** 3,
    5 * 10 ** 3,
    10 ** 4,
    2 * 10 ** 4,
    3 * 10 ** 4,
    4 * 10 ** 4,
    5 * 10 ** 4,
    7 * 10 ** 4,
    10 ** 5,
    15 * 10 ** 4,
    2 * 10 ** 5,
    10 ** 6,
];

export const isUplPlayerPage = (pageUrl: string) => {
    return /^https:\/\/upl\.ua\/en\/people\/view\/\d+$/.test(pageUrl);
};

export const getUplPlayerId = (url: string) => {
    /*
     * Url example: "https://upl.ua/en/people/view/67386"
     * We need an id for the player
     */
    const parts = url.split('/');
    const playerIdKey = parts.findIndex((part) => part === 'view');
    const playerId = parts[playerIdKey + 1];

    return playerId;
};

export const isTmPlayerPage = (pageUrl: string) => {
    return /^https:\/\/www\.transfermarkt\.(com|world|at|be|ch|de|fr|co.in|jp|nl|pt|co.za|com.ar|us|com.tr|pl|mx|it|es|co|com.br|co.uk|ru)\/.+\/profil\/spieler\/\d+$/.test(
        pageUrl,
    );
};

export const getTmPlayerId = (url: string) => {
    /*
     * Url example: "https://www.transfermarkt.com.br/jan-lastuvka/profil/spieler/28186"
     * We need english alternative on "https://www.transfermarkt.com/jan-lastuvka/profil/spieler/28186"
     * We need an id for the player
     */

    if (!isTmPlayerPage(url)) {
        return null;
    }

    const parts = url.split('/');
    const playerIdKey = parts.findIndex((part) => part === 'spieler');
    const playerId = parts[playerIdKey + 1];

    return playerId;
};

export const isUafStartPlayerPage = (pageUrl: string) => {
    return /^https:\/\/start\.uaf\.ua\/players\/\d+$/.test(pageUrl);
};

export const getUafStartPlayerId = (url: string) => {
    /*
     * Url example: "https://start.uaf.ua/players/30080683"
     * We need an id for the player
     */

    if (!isUafStartPlayerPage(url)) {
        return null;
    }

    const parts = url.split('/');
    const playerIdKey = parts.findIndex((part) => part === 'players');
    const playerId = parts[playerIdKey + 1];

    return playerId || null;
};

export const isOgolNewPlayerUrl = (pageUrl: string) => {
    return /^https:\/\/www\.(playmakerstats.com|ceroacero.es|ogol.com.br|zerozero.pt|calciozz.it|leballonrond.fr|fussballzz.de|soccerzz.com)\/+(jogador|player)\/[\w-]+\/\d+$/.test(
        pageUrl,
    );
};

export const isOgolOldPlayerUrl = (pageUrl: string) => {
    return /^https:\/\/www\.(playmakerstats.com|ceroacero.es|ogol.com.br|zerozero.pt|calciozz.it|leballonrond.fr|fussballzz.de|soccerzz.com)\/(jogador|player)\.php\?id=\d+$/.test(
        pageUrl,
    );
};

export const isOgolPlayerPage = (pageUrl: string) => {
    return isOgolNewPlayerUrl(pageUrl) || isOgolOldPlayerUrl(pageUrl);
};

export const getOgolPlayerId = (url: string) => {
    if (isOgolOldPlayerUrl(url)) {
        /*
         * Url example: "https://www.ogol.com.br/player.php?id=776975"
         * We need an id for the player
         */
        const targetUrl = new URL(url);
        const playerId = targetUrl.searchParams.get('id') || '';

        return playerId || null;
    }

    if (isOgolNewPlayerUrl(url)) {
        /*
         * New url example: "https://www.ogol.com.br/jogador/jhon-miller/965755"
         * We need an id for the player
         */
        const targetUrl = new URL(url);
        const playerId = targetUrl.pathname.split('/').pop() as string;

        return playerId || null;
    }

    return null;
};

export type PlayersFilters = {
    name: string;
    height: NumbersRange;
    age: NumbersRange;
    foot: string | null;
    position: ScoutingPosition | null;
    countries: string[];
    profile: Map<PlayerCharacteristic, Set<string>>;
    quality: NumbersRange;
    potential: NumbersRange;
    salary: NumbersRange;
    contractExpirationMonths: NumbersRange;
    transferFee: NumbersRange;
    reference: string;
    club: string;
};

export const defaultFilters: PlayersFilters = {
    name: '',
    height: { min: 150, max: 250 },
    age: { min: 10, max: 45 },
    foot: null,
    position: null,
    countries: [],
    profile: new Map(),
    quality: { min: 0.5, max: 5.5 },
    potential: { min: 0.5, max: 5.5 },
    salary: { min: 0, max: salaryValues[salaryValues.length - 1] },
    contractExpirationMonths: { min: 0, max: 24 },
    transferFee: { min: 0, max: transferFeeValues[transferFeeValues.length - 1] },
    reference: '',
    club: '',
};

export type PlayersInfoList = Array<ReturnType<typeof parsePlayerInfo> & { id: string }>;

export const filterPlayers = (
    playersInfoList: PlayersInfoList,
    filters: PlayersFilters,
): PlayersInfoList => {
    let result = [...playersInfoList];
    const {
        name,
        height,
        age,
        foot,
        position,
        countries,
        quality,
        potential,
        salary,
        contractExpirationMonths,
        transferFee,
        reference,
        club,
    } = filters;

    if (position) {
        const targetPlayersPositions = scoutingGroupsMap.get(position);
        result = targetPlayersPositions
            ? result.filter(({ positions }) => {
                  return targetPlayersPositions.some((pos) => Boolean(positions[pos]));
              })
            : result;
    }

    if (countries.length > 0) {
        result = result.filter((player) => {
            return player.countries.some((country) => countries.includes(country));
        });
    }

    if (name && name.length >= 3) {
        result = result.filter(({ fullName }) => {
            const textParts = filters.name.split(' ');

            return textParts.some((textPart) => fullName.includes(textPart));
        });
    }

    if (club && club.length >= 3) {
        result = result.filter((player) => {
            const textParts = club.split(' ');

            return textParts.some((textPart) => player.club?.[0]?.includes(textPart));
        });
    }

    if (foot) {
        result = result.filter((playerInfo) => {
            return playerInfo.foot === foot;
        });
    }

    if (height.min !== defaultFilters.height.min || height.max !== defaultFilters.height.max) {
        result = result.filter((playerInfo) => {
            if (!playerInfo.height) {
                return false;
            }

            return playerInfo.height >= height.min && playerInfo.height <= height.max;
        });
    }

    if (height.min !== defaultFilters.height.min || height.max !== defaultFilters.height.max) {
        result = result.filter((playerInfo) => {
            if (!playerInfo.quality) {
                return false;
            }

            return playerInfo.quality >= quality.min && playerInfo.quality <= quality.max;
        });
    }

    if (age.min !== defaultFilters.age.min || age.max !== defaultFilters.age.max) {
        result = result.filter(({ dateOfBirth }) => {
            if (!dateOfBirth) {
                return false;
            }

            const playerAge = differenceInYears(new Date(), dateOfBirth);

            return playerAge >= age.min && playerAge <= age.max;
        });
    }

    if (
        contractExpirationMonths.min !== defaultFilters.contractExpirationMonths.min ||
        contractExpirationMonths.max !== defaultFilters.contractExpirationMonths.max
    ) {
        result = result.filter(({ expirationDate }) => {
            if (!expirationDate) {
                return false;
            }

            const expMonths = differenceInMonths(expirationDate, new Date());

            if (expMonths < 0) {
                return true;
            }

            return (
                expMonths >= contractExpirationMonths.min &&
                expMonths <= contractExpirationMonths.max
            );
        });
    }

    if (quality.min !== defaultFilters.quality.min || quality.max !== defaultFilters.quality.max) {
        result = result.filter((playerInfo) => {
            if (!playerInfo.quality) {
                return false;
            }

            return playerInfo.quality >= quality.min && playerInfo.quality <= quality.max;
        });
    }

    if (
        potential.min !== defaultFilters.potential.min ||
        potential.max !== defaultFilters.potential.max
    ) {
        result = result.filter((playerInfo) => {
            if (!playerInfo.potential) {
                return false;
            }

            return playerInfo.potential >= potential.min && playerInfo.potential <= potential.max;
        });
    }

    if (salary.min !== defaultFilters.salary.min || salary.max !== defaultFilters.salary.max) {
        result = result.filter((playerInfo) => {
            if (!playerInfo.salary) {
                return false;
            }

            return playerInfo.salary >= salary.min && playerInfo.salary <= salary.max;
        });
    }

    if (
        transferFee.min !== defaultFilters.transferFee.min ||
        transferFee.max !== defaultFilters.transferFee.max
    ) {
        result = result.filter((playerInfo) => {
            if (!playerInfo.transferFee) {
                return false;
            }

            return (
                playerInfo.transferFee >= transferFee.min &&
                playerInfo.transferFee <= transferFee.max
            );
        });
    }

    if (reference) {
        const refPlayerIds = [
            getTmPlayerId(reference),
            getOgolPlayerId(reference),
            getUplPlayerId(reference),
            getUafStartPlayerId(reference),
        ].filter(Boolean) as string[];

        result =
            refPlayerIds.length > 0
                ? result.filter((playerInfo) => {
                      return refPlayerIds.some((refPlayerId) =>
                          playerInfo.references.includes(refPlayerId),
                      );
                  })
                : [];
    }

    return result;
};
