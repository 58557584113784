import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkIcon } from 'common/icons';
import UserLinks from 'common/layout/UserLinks';
import { styled, SxProps } from '@mui/material/styles';
import { useCurrentUser } from 'entities/users/users.utils';
import { CardBody, CardHeader, CardRoot } from 'common/entity-updates/CommonComponents';

const Root = styled(CardRoot)(() => ({
    display: 'flex',
    width: 'auto',
}));

const LinksList = styled(UserLinks)(({ theme }) => ({
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: 'calc(100% - 16px)',
    maxWidth: 'calc(100vw - 16px)',

    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        width: 'fit-content',
        maxWidth: 'calc(100vw - 100px)',
    },
}));

type UserResoursesProps = {
    sx?: SxProps;
};

const UserResourses: React.FC<UserResoursesProps> = ({ ...rest }) => {
    const { t } = useTranslation();
    const user = useCurrentUser();

    if (!user) {
        return null;
    }

    return (
        <Root {...rest}>
            <CardHeader
                title={t('home.userResourses.title')}
                IconComponent={
                    <LinkIcon
                        sx={{
                            width: 16,
                            height: 16,
                            mr: 1,
                            color: (theme) => theme.palette.primary.main,
                        }}
                    />
                }
            />
            <CardBody>
                <LinksList />
            </CardBody>
        </Root>
    );
};

export default UserResourses;
