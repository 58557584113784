import PlayerType, { PlayerData, PlayerSkills } from './Player';
import { PlayerUpdate, PlayerUpdateType } from './PlayerUpdates';
import actionTypes from './players.actionTypes';

export const playerDataRequest = ({
    playerId,
    organizationId,
    onSuccess,
    onFail,
}: {
    playerId: string;
    organizationId: string;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.PLAYER_DATA_REQUEST,
    playerId,
    organizationId,
    onSuccess,
    onFail,
});

export const playersByIdsRequest = ({
    playersIds,
    organizationId,
    onFail,
    onSuccess,
}: {
    playersIds: string[];
    organizationId: string;
    onFail: VoidFunction;
    onSuccess: VoidFunction;
}) => ({
    type: actionTypes.PLAYERS_BY_IDS_REQUEST,
    playersIds,
    organizationId,
    onFail,
    onSuccess,
});

export const scoutingListPlayersByIdsRequest = ({
    playersIds,
    organizationId,
    listId,
    onFail,
    onSuccess,
}: {
    playersIds: string[];
    organizationId: string;
    listId: string;
    onFail: VoidFunction;
    onSuccess: VoidFunction;
}) => ({
    type: actionTypes.SCOUTING_LIST_PLAYERS_BY_IDS_REQUEST,
    playersIds,
    organizationId,
    listId,
    onFail,
    onSuccess,
});

export const playerDataReceived = (playerData: PlayerType) => ({
    type: actionTypes.PLAYER_DATA_RECEIVED,
    playerData,
});

export const requestTeamPlayersList = ({
    teamId,
    organizationId,
}: {
    teamId: string;
    organizationId: string;
}) => ({
    type: actionTypes.TEAM_PLAYERS_LIST_REQUEST,
    teamId,
    organizationId,
});

export const requestAgentPlayersList = ({
    agentId,
    organizationId,
}: {
    agentId: string;
    organizationId: string;
}) => ({
    type: actionTypes.AGENT_PLAYERS_LIST_REQUEST,
    agentId,
    organizationId,
});

export const requestPlayerUpdatesList = ({
    organizationId,
    playerId,
    updatesType,
}: {
    organizationId: string;
    playerId: string;
    updatesType?: PlayerUpdateType;
}) => ({
    type: actionTypes.PLAYERS_UPDATES_LIST_REQUEST,
    organizationId,
    playerId,
    updatesType,
});

export const playerUpdatesListReceived = ({
    playerUpdatesList,
}: {
    playerUpdatesList: PlayerUpdate[];
}) => ({
    type: actionTypes.PLAYERS_UPDATES_LIST_RECEIVED,
    playerUpdatesList,
});

export const playersListReceived = (playersList: PlayerType[]) => ({
    type: actionTypes.PLAYERS_LIST_RECEIVED,
    playersList,
});

export const teamPlayersRequested = (teamId: string) => ({
    type: actionTypes.TEAM_PLAYERS_REQUESTED,
    teamId,
});

export const scoutingClubPlayersRequest = ({
    clubId,
    organizationId,
}: {
    clubId: string;
    organizationId: string;
}) => ({
    type: actionTypes.SCOUTING_CLUB_PLAYERS_REQUESTED,
    clubId,
    organizationId,
});

export const scoutingRecentPlayersRequest = ({
    organizationId,
    onFinish,
}: {
    organizationId: string;
    onFinish: VoidFunction;
}) => ({
    type: actionTypes.SCOUTING_RECENT_PLAYERS_REQUEST,
    organizationId,
    onFinish,
});

export const scoutingNoRefPlayersRequest = ({
    organizationId,
    onFinish,
}: {
    organizationId: string;
    onFinish: VoidFunction;
}) => ({
    type: actionTypes.SCOUTING_NO_REF_PLAYERS_REQUEST,
    organizationId,
    onFinish,
});

export const playersListLoadError = () => ({
    type: actionTypes.PLAYERS_LIST_LOAD_ERROR,
});

export const removePlayerFromLocalState = ({ playerId }: { playerId: string }) => ({
    type: actionTypes.REMOVE_PLAYER_DATA,
    playerId,
});

export const updatePlayer = ({
    playerId,
    organizationId,
    playerData,
    onSuccess,
}: {
    playerId: string;
    organizationId: string;
    playerData: PlayerData;
    onSuccess?: () => void;
}) => ({
    type: actionTypes.UPDATE_PLAYER_DATA,
    playerId,
    organizationId,
    playerData,
    onSuccess,
});

export const removePlayer = ({
    playerId,
    onSuccess,
}: {
    playerId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.REMOVE_PLAYER,
    playerId,
    onSuccess,
});

export const setRemovedPlayer = ({ playerId }: { playerId: string }) => ({
    type: actionTypes.SET_REMOVED_PLAYER,
    playerId,
});

export const playerSkillsRequest = (playerId: string) => ({
    type: actionTypes.PLAYERS_SKILLS_REQUEST,
    playerId,
});

export const playerSkillsReceived = ({
    playerId,
    skills,
}: {
    playerId: string;
    skills: PlayerSkills;
}) => ({
    type: actionTypes.PLAYERS_SKILLS_RECEIVED,
    playerId,
    skills,
});

export const updatePlayerSkills = ({
    playerId,
    skills,
}: {
    playerId: string;
    skills: PlayerSkills;
}) => ({
    type: actionTypes.UPDATE_PLAYERS_SKILLS,
    playerId,
    skills,
});
