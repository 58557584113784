import React, { useCallback } from 'react';
import { TFunction } from 'i18next';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import OrgLogo from 'features/organization/components/OrgLogo';
import { deleteOwnAccess } from 'entities/access/access.actions';
import DeleteIconButton from 'common/components/DeleteIconButton';
import AccessRecord, { AccessTarget } from 'entities/access/Access';
import { Box, ButtonBase, IconButton, Tooltip, Typography } from 'common/components';
import { buildLink } from './sidebar.utils';

const ResourceLink = styled(NavLink)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.shape.borderRadius,
    lineHeight: '24px',
    width: 170,

    '&:hover': {
        color: 'rgba(0, 0, 0, 1)',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
}));

const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(0.5, 1),
    color: 'rgba(0, 0, 0, 0.54)',
}));

const getErrorMessage = (errorCode: string, t: TFunction) => {
    switch (errorCode) {
        case 'unauthenticated':
            return {
                title: t('access.deleteOwnAccess.error.unauthenticated.title'),
                description: t('access.deleteOwnAccess.error.unauthenticated.description'),
            };
        case 'last-user-access-record-error':
            return {
                title: t('access.deleteOwnAccess.error.lastUserAccessRecord.title'),
                description: t('access.deleteOwnAccess.error.lastUserAccessRecord.description'),
            };
        case 'access-record-not-found':
            return {
                title: t('access.deleteOwnAccess.error.accessRecordNotFound.title'),
                description: t('access.deleteOwnAccess.error.accessRecordNotFound.description'),
            };
        default:
            return {
                title: t('access.deleteOwnAccess.error.unknown.title'),
                description: t('access.deleteOwnAccess.error.unknown.description'),
            };
    }
};

type AccessGroupProps = {
    title: string;
    IconComponent: React.FC;
    accessList: Array<AccessRecord>;
    shouldHideIfEmpty?: boolean;
    emptyPlaceholder?: string;
    SideComponent?: React.ReactElement;
    allowDelete?: boolean;
    checkMatch: (target: AccessTarget) => boolean;
    onSelect?: VoidFunction;
};

const AccessGroup: React.FC<AccessGroupProps> = ({
    accessList,
    title,
    IconComponent,
    shouldHideIfEmpty,
    emptyPlaceholder,
    SideComponent,
    allowDelete,
    checkMatch,
    onSelect,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleDelete = useCallback(
        (gid: string) => {
            dispatch(
                deleteOwnAccess({
                    gid,
                    onSuccess: () => {
                        toastr.success('Success', 'Access deleted');
                    },
                    onFail: (messageCode: string) => {
                        const { title, description } = getErrorMessage(messageCode, t);
                        toastr.error(title, description);
                    },
                }),
            );
        },
        [t],
    );

    if (accessList.length === 0 && shouldHideIfEmpty) {
        return null;
    }

    return (
        <Root>
            <Box sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
                <Tooltip title={title}>
                    <IconButton color="inherit">
                        <IconComponent />
                    </IconButton>
                </Tooltip>
                <ButtonBase component={Typography} sx={{ ml: 1, fontWeight: 600 }} noWrap>
                    {title}
                </ButtonBase>
                {SideComponent}
            </Box>
            <Box sx={{ mt: 1, ml: 3, borderLeft: '1px solid #ccc', pl: 1.5 }}>
                {accessList.length > 0 &&
                    accessList.map((accessRecord) => (
                        <Box sx={{ display: 'flex', alignItems: 'center' }} key={accessRecord.gid}>
                            <ResourceLink to={buildLink(accessRecord)} onClick={onSelect}>
                                {accessRecord.target.organizationId && (
                                    <OrgLogo
                                        sx={{ m: 0, mr: 1 }}
                                        size={24}
                                        organizationId={accessRecord.target.organizationId}
                                    />
                                )}
                                <Typography
                                    sx={{
                                        color: (theme) =>
                                            checkMatch(accessRecord.target)
                                                ? theme.palette.primary.main
                                                : 'rgba(0, 0, 0, 0.54)',
                                    }}
                                    variant="body1"
                                    noWrap
                                >
                                    {accessRecord.targetName}
                                </Typography>
                            </ResourceLink>
                            {allowDelete && (
                                <DeleteIconButton
                                    submitText={t('access.deleteOwnAccess.confirmationText')}
                                    onDelete={() => handleDelete(accessRecord.gid!)}
                                    sx={{ ml: 1 }}
                                />
                            )}
                        </Box>
                    ))}
                {accessList.length === 0 && (
                    <Typography sx={{ py: 1, opacity: 0.5 }} noWrap>
                        {emptyPlaceholder}
                    </Typography>
                )}
            </Box>
        </Root>
    );
};

export default AccessGroup;
