import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { styled } from '@mui/material/styles';
import {
    ClickAwayListener,
    Drawer,
    IconButton,
    TelegramBtn,
    Tooltip,
    Typography,
    Box,
} from 'common/components';
import { ArrowLeftIcon, ArrowRightIcon, MenuIcon, CloseIcon } from 'common/icons';
import logoImg from 'img/under-sports-logo-black-narrow.png';
import HelpButton from '../help/HelpButton';
import AuthIcon from './AuthIcon';
import OfflineStatus from './OfflineStatus';
import TeamNav from './TeamNav';
import NotificationsBtn from 'common/components/notifications/NotificationsBtn';
import { useMobileNavigation } from './useMobileNavigation';
import WhatsAppBtn from 'common/components/WhatsAppBtn';

const PREFIX = 'Sidebar';

const classes = {
    logoLink: `${PREFIX}-logoLink`,
    logo: `${PREFIX}-logo`,
    logoSeparator: `${PREFIX}-logoSeparator`,
    passLogo: `${PREFIX}-passLogo`,
    sidebar: `${PREFIX}-sidebar`,
    header: `${PREFIX}-header`,
    drawer: `${PREFIX}-drawer`,
    paper: `${PREFIX}-paper`,
    drawerOpen: `${PREFIX}-drawerOpen`,
    drawerClose: `${PREFIX}-drawerClose`,
    divider: `${PREFIX}-divider`,
    mobileToggleButton: `${PREFIX}-mobileToggleButton`,
    toggleButton: `${PREFIX}-toggleButton`,
    text: `${PREFIX}-text`,
    bottomButtons: `${PREFIX}-bottomButtons`,
    headerTeamNav: `${PREFIX}-headerTeamNav`,
    sideTeamNav: `${PREFIX}-sideTeamNav`,
    headerHavBtn: `${PREFIX}-headerHavBtn`,
};

const DRAWER_WIDTH: number = 240;

const StyledSidebar = styled('div')(({ theme }) => ({
    position: 'relative',
    zIndex: 999,
    width: 0,
    flexShrink: 0,

    [theme.breakpoints.up('sm')]: {
        width: theme.spacing(8),
    },

    [`& .${classes.logoLink}`]: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
        alignItems: 'center',
    },

    [`& .${classes.logo}`]: {
        width: 54,
    },

    [`& .${classes.logoSeparator}`]: {
        margin: '2px 0 4px',
        fontWeight: 500,
    },

    [`& .${classes.passLogo}`]: {
        width: 50,
        opacity: 0.9,
    },

    [`& .${classes.header}`]: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        height: theme.spacing(6),
        boxShadow: theme.custom.boxShadow.main,
        width: '100vw',

        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },

    [`& .${classes.drawer}`]: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },

    [`& .${classes.paper}`]: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2, 1),
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
        boxShadow: theme.custom.boxShadow.main,
        border: 'none',
        overflow: 'hidden',
        background: theme.custom.background.gradient,
    },

    [`& .${classes.drawerOpen}`]: {
        width: DRAWER_WIDTH,
        backgroundColor: '#fff',
        transition: theme.transitions.create(['width', 'background-color'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    [`& .${classes.drawerClose}`]: {
        transition: theme.transitions.create(['width', 'background-color'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(8),
        display: 'none',

        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },

    [`& .${classes.divider}`]: {
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.mobileToggleButton}`]: {
        width: 40,
        height: 40,
        opacity: 0.7,
        margin: theme.spacing(0, 1, 0, 'auto'),
    },

    [`& .${classes.toggleButton}`]: {
        position: 'absolute',
        bottom: 88,
        background: '#fff',
        borderRadius: '50%',
        border: '1px solid rgba(0, 0, 0, .12)',
        zIndex: 10000,
        transition: theme.transitions.create('right', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        transform: 'translateX(64px)',
        width: 40,
        height: 40,
        opacity: 0.7,
        display: 'none',

        [theme.breakpoints.up('sm')]: {
            transform: 'none',
            display: 'flex',
        },
    },

    [`& .${classes.text}`]: {
        marginLeft: theme.spacing(3),
    },

    [`& .${classes.bottomButtons}`]: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 'auto',
    },

    [`& .${classes.headerTeamNav}`]: {
        padding: theme.spacing(0, 1),
    },

    [`& .${classes.sideTeamNav}`]: {
        flexDirection: 'column',
    },

    [`& .${classes.headerHavBtn}`]: {
        backgroundColor: theme.custom.background.light,
        padding: 0,
        width: 32,
        height: 32,
        justifyContent: 'center',
        margin: theme.spacing(0, 0.5, 0, 0),
    },
}));

type SidebarProps = {};

const Sidebar: React.FC<SidebarProps> = () => {
    const [isExpanded, setExpanded] = React.useState(false);
    const { t } = useTranslation();
    const isMobileApp = useMobileNavigation();

    const toggleDrawer = useCallback(() => {
        setExpanded(!isExpanded);
    }, [isExpanded]);

    useEffect(() => {
        function handleKeyPress(event: KeyboardEvent) {
            if (['Esc', 'Escape'].includes(event.key)) {
                setExpanded(false);
            }
        }

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const handleClickAway = useCallback(() => {
        if (isExpanded) {
            toggleDrawer();
        }
    }, [isExpanded]);

    if (isMobileApp) {
        return null;
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <StyledSidebar>
                <Drawer
                    variant="permanent"
                    className={classNames(classes.drawer, {
                        [classes.drawerOpen]: isExpanded,
                        [classes.drawerClose]: !isExpanded,
                    })}
                    classes={{
                        paper: classNames(classes.paper, {
                            [classes.drawerOpen]: isExpanded,
                            [classes.drawerClose]: !isExpanded,
                        }),
                    }}
                >
                    <Link to="/" className={classes.logoLink}>
                        <img className={classes.logo} src={logoImg} alt="UnderSports" />
                    </Link>
                    <TeamNav
                        className={classes.sideTeamNav}
                        isExpanded={isExpanded}
                        onClose={() => setExpanded(false)}
                    />
                    <div className={classes.bottomButtons}>
                        <NotificationsBtn
                            sx={{ mx: isExpanded ? 0 : 'auto', my: 0.5 }}
                            isExpanded={isExpanded}
                        />
                        {isExpanded && (
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="caption">
                                    {t('sidebar.haveQuestions')}
                                </Typography>
                                <TelegramBtn size="small" sx={{ mb: 0.5 }} />
                                <WhatsAppBtn size="small" />
                            </Box>
                        )}
                        <HelpButton isExpanded={isExpanded} />
                        <OfflineStatus />
                        <AuthIcon isExpanded={isExpanded} setExpanded={setExpanded} />
                    </div>
                </Drawer>
                <Tooltip
                    title={String(
                        isExpanded ? t('sidebar.collapseSidebar') : t('sidebar.expandSidebar'),
                    )}
                >
                    <IconButton
                        style={{ right: isExpanded ? -16 - DRAWER_WIDTH + 64 : -16 }}
                        className={classes.toggleButton}
                        onClick={toggleDrawer}
                    >
                        {isExpanded ? <ArrowLeftIcon /> : <ArrowRightIcon />}
                    </IconButton>
                </Tooltip>
                <header className={classes.header}>
                    <TeamNav
                        isTopNav
                        className={classes.headerTeamNav}
                        navItemClassName={classes.headerHavBtn}
                    />
                    <NotificationsBtn sx={{ ml: 'auto' }} />
                    <IconButton className={classes.mobileToggleButton} onClick={toggleDrawer}>
                        {isExpanded ? <CloseIcon /> : <MenuIcon />}
                    </IconButton>
                </header>
            </StyledSidebar>
        </ClickAwayListener>
    );
};

export default Sidebar;
