export default {
    PLAYER_DATA_REQUEST: 'ENTITIES/PLAYERS/PLAYER_DATA_REQUEST',
    PLAYERS_BY_IDS_REQUEST: 'ENTITIES/PLAYERS/PLAYERS_BY_IDS_REQUEST',
    SCOUTING_LIST_PLAYERS_BY_IDS_REQUEST: 'ENTITIES/PLAYERS/SCOUTING_LIST_PLAYERS_BY_IDS_REQUEST',
    PLAYER_DATA_RECEIVED: 'ENTITIES/PLAYERS/PLAYER_DATA_RECEIVED',
    SCOUTING_RECENT_PLAYERS_REQUEST: 'ENTITIES/PLAYERS/SCOUTING_RECENT_PLAYERS_REQUEST',
    SCOUTING_NO_REF_PLAYERS_REQUEST: 'ENTITIES/PLAYERS/SCOUTING_NO_REF_PLAYERS_REQUEST',
    SCOUTING_CLUB_PLAYERS_REQUESTED: 'ENTITIES/PLAYERS/SCOUTING_CLUB_PLAYERS_REQUESTED',
    TEAM_PLAYERS_LIST_REQUEST: 'ENTITIES/PLAYERS/TEAM_PLAYERS_LIST_REQUEST',
    PLAYERS_LIST_RECEIVED: 'ENTITIES/PLAYERS/PLAYERS_LIST_RECEIVED',
    PLAYERS_LIST_LOAD_ERROR: 'ENTITIES/PLAYERS/PLAYERS_LIST_LOAD_ERROR',
    AGENT_PLAYERS_LIST_REQUEST: 'ENTITIES/PLAYERS/AGENT_PLAYERS_LIST_REQUEST',
    REMOVE_PLAYER_DATA: 'ENTITIES/PLAYERS/REMOVE_PLAYER_DATA',
    UPDATE_PLAYER_DATA: 'ENTITIES/PLAYERS/UPDATE_PLAYER_DATA',
    TEAM_PLAYERS_REQUESTED: 'ENTITIES/PLAYERS/TEAM_PLAYERS_REQUESTED',
    REMOVE_PLAYER: 'ENTITIES/PLAYERS/REMOVE_PLAYER',
    SET_REMOVED_PLAYER: 'ENTITIES/PLAYERS/SET_REMOVED_PLAYER',
    PLAYERS_UPDATES_LIST_RECEIVED: 'ENTITIES/PLAYERS/PLAYERS_UPDATES_LIST_RECEIVED',
    PLAYERS_UPDATES_LIST_REQUEST: 'ENTITIES/PLAYERS/PLAYERS_UPDATES_LIST_REQUEST',
    PLAYERS_SKILLS_REQUEST: 'ENTITIES/PLAYERS/PLAYERS_SKILLS_REQUEST',
    PLAYERS_SKILLS_RECEIVED: 'ENTITIES/PLAYERS/PLAYERS_SKILLS_RECEIVED',
    UPDATE_PLAYERS_SKILLS: 'ENTITIES/PLAYERS/UPDATE_PLAYERS_SKILLS',
};
