import { getLonglistUrl, getOrgLonglistsUrl, getTeamUrl } from 'common/utils/urlUtils';
import AccessRecord, { AccessTypes, Roles } from 'entities/access/Access';
import { PlanningPeriods, ScheduleViewTypes } from 'features/calendar/Calendar';

export const buildLink = (accessRecord: AccessRecord) => {
    const { accessType, target, role } = accessRecord;

    if (!accessType || !target) {
        return '/';
    }

    const { organizationId, teamId, playerId, methodologyId, listId } = target;

    if (accessType === AccessTypes.methodology) {
        return `/methodologies/${methodologyId}`;
    }

    if (accessType === AccessTypes.team) {
        return `/organizations/${organizationId}/teams/${teamId}/${ScheduleViewTypes.calendar}/${PlanningPeriods.week}`;
    }

    if (accessType === AccessTypes.organization) {
        if (role === Roles.scout) {
            return getOrgLonglistsUrl(organizationId!);
        }

        return `/organizations/${organizationId}`;
    }

    if (accessType === AccessTypes.player) {
        return `/players/${playerId}`;
    }

    if (accessType === AccessTypes.longlist) {
        return getLonglistUrl(organizationId!, listId!);
    }

    if (accessType === AccessTypes.shadowTeam) {
        return `/organizations/${organizationId}/scouting/shadowteams/${listId}`;
    }

    return '/';
};

export const checkIfDashboardActive = (pathname: string) =>
    /^\/organizations\/(\w|\d)+\/teams\/(\w|\d)+$/.test(pathname);

export const checkIfCalendarActive = (pathname: string) =>
    pathname.includes(ScheduleViewTypes.planning) || pathname.includes(ScheduleViewTypes.calendar);

export const checkIfPlayersActive = (pathname: string) =>
    (pathname.includes('/players') || pathname.includes('/goals')) &&
    !pathname.includes('/evaluation');

export const checkIfMedicalActive = (pathname: string) => pathname.includes('/medical');

export const checkIfEvaluationActive = (pathname: string) => pathname.includes('/evaluations');

export const checkIfStatisticsActive = (pathname: string) => pathname.includes('/statistics');

export const checkIfConditionsActive = (pathname: string) => pathname.includes('/conditions');

export const checkIfFilesActive = (pathname: string) => pathname.includes('/files');

export const checkIfFitnessDataActive = (pathname: string) => pathname.includes('/fitness-data');

export const checkIfReportsActive = (pathname: string) => pathname.includes('/reports');

export const getCalendarUrl = (pathname: string, organizationId: string, teamId: string) => {
    const isCalendarActive = checkIfCalendarActive(pathname);
    const base = getTeamUrl(organizationId, teamId);

    if (!isCalendarActive) {
        return [base, ScheduleViewTypes.calendar, PlanningPeriods.week].join('/');
    }

    if (pathname.includes(PlanningPeriods.month)) {
        return [base, ScheduleViewTypes.calendar, PlanningPeriods.week].join('/');
    }

    if (pathname.includes(ScheduleViewTypes.planning) && pathname.includes(PlanningPeriods.week)) {
        return [base, ScheduleViewTypes.planning, PlanningPeriods.month].join('/');
    }

    return [base, ScheduleViewTypes.planning, PlanningPeriods.week].join('/');
};
