import { Button, Dialog, DialogContent } from 'common/components';
import classNames from 'classnames';
import CreateOrganizationForm from 'features/backoffice/components/CreateOrganizationForm';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type CreateOrgBtnProps = {
    className?: string;
};

const CreateOrgBtn: React.FC<CreateOrgBtnProps> = ({ className, ...rest }) => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSuccess = useCallback(
        ({ organizationId }: { organizationId: string }) => {
            navigate(`/organizations/${organizationId}`);
        },
        [navigate],
    );

    return (
        <>
            <Button
                className={classNames(className)}
                color="primary"
                size="small"
                variant="contained"
                onClick={() => setIsDialogOpen(true)}
                {...rest}
            >
                {t('help.wellcomeGuide.createOrganizationBtn')}
            </Button>
            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogContent>
                    <CreateOrganizationForm
                        onSuccess={handleSuccess}
                        onClose={() => setIsDialogOpen(false)}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CreateOrgBtn;
