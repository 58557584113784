import { EventTypes } from 'entities/events/Event';
import { Author } from 'entities/users/User';

export enum AggType {
    sum = 'sum',
    avg = 'avg',
}

export enum MathOperations {
    divide = 'divide',
    multiply = 'multiply',
    sum = 'sum',
}

export type CalcStep = {
    stepId: string;
    action: MathOperations;
    args: Array<NumericFitnessDataKeys | string | number>;
};

export type CustomColumn = {
    id: string;
    name: string;
    shortName: string;
    calc: CalcStep[];
};

export type ColumnConfig = {
    param: NumericFitnessDataKeys;
    summary: AggType[];
    aggType: AggType | null;
    isCustom: boolean;
};

export type FitnessDataReportConfig = {
    columnsConfig: ColumnConfig[];
    customColumns: CustomColumn[];
};

export type ColumnsMapping = Record<DataProvider, Record<string, string>>;
export type PlayersMapping = Record<DataProvider, Record<string, string>>;

export type FitnessDataMetadata = {
    playersMapping: PlayersMapping;
    columnsMapping: ColumnsMapping;
    /* config by teamId */
    reportConfig: Record<string, FitnessDataReportConfig>;
};

export enum DataProvider {
    gpExe = 'gpExe',
    unset = 'unset',
}

export enum NumericFitnessDataKeys {
    duration = 'duration',
    totalTime = 'totalTime',
    distance = 'distance',
    avgSpeed = 'avgSpeed',
    avgHR = 'avgHR',
    maxSpeed = 'maxSpeed',
    maxAcc = 'maxAcc',
    maxHR = 'maxHR',
    maxMetPower = 'maxMetPower',
    metPowerEvents = 'metPowerEvents',
    speedEvents = 'speedEvents',
    distanceSpeedZ3 = 'distanceSpeedZ3',
    timeSpeedZ3 = 'timeSpeedZ3',
    distanceSpeedZ4 = 'distanceSpeedZ4',
    timeSpeedZ4 = 'timeSpeedZ4',
    distanceSpeedZ5 = 'distanceSpeedZ5',
    timeSpeedZ5 = 'timeSpeedZ5',
    maxDec = 'maxDec',
    distanceSpeedZ1 = 'distanceSpeedZ1',
    timeSpeedZ1 = 'timeSpeedZ1',
    distanceSpeedZ2 = 'distanceSpeedZ2',
    timeSpeedZ2 = 'timeSpeedZ2',
    accEvents = 'accEvents',
    decEvents = 'decEvents',
    eqDistance = 'eqDistance',
    eqDistanceIndex = 'eqDistanceIndex',
    avgMP = 'avgMP',
    energy = 'energy',
    anEnergy = 'anEnergy',
    rpe = 'rpe',
    rpeDuration = 'rpeDuration',
    effIndex = 'effIndex',
    anIndex = 'anIndex',
    aerobicRatio = 'aerobicRatio',
    avgVO2 = 'avgVO2',
    distanceMetPowerZ1 = 'distanceMetPowerZ1',
    distanceMetPowerZ2 = 'distanceMetPowerZ2',
    MPEavgTime = 'MPEavgTime',
    MPEavgMetPower = 'MPEavgMetPower',
    MPErecAvgTime = 'MPErecAvgTime',
    MPErecAvgMetPower = 'MPErecAvgMetPower',
    MPEtimeZ1 = 'MPEtimeZ1',
    MPEtimeZ2 = 'MPEtimeZ2',
    MPEtimeZ3 = 'MPEtimeZ3',
    MPEmaxSpeedZ1 = 'MPEmaxSpeedZ1',
    MPEdistanceZ3 = 'MPEdistanceZ3',
    MPEdistanceZ2 = 'MPEdistanceZ2',
    MPEdistanceZ1 = 'MPEdistanceZ1',
    MPEmaxSpeedZ2 = 'MPEmaxSpeedZ2',
    MPEmaxSpeedZ3 = 'MPEmaxSpeedZ3',
    extWorkPlus = 'extWorkPlus',
    highExtWorkPlus = 'highExtWorkPlus',
    highExtWorkMinus = 'highExtWorkMinus',
    avgExtPowerPlus = 'avgExtPowerPlus',
    eccentricIndex = 'eccentricIndex',
    highExtWorkPlusForce = 'highExtWorkPlusForce',
    highExtWorkMinusSpeed = 'highExtWorkMinusSpeed',
    highExtWorkMinusPower = 'highExtWorkMinusPower',
    highExtWorkMinusForce = 'highExtWorkMinusForce',
    highExtWorkPlusPower = 'highExtWorkPlusPower',
    highExtWorkPlusSpeed = 'highExtWorkPlusSpeed',
    bursts = 'bursts',
    brakes = 'brakes',
    walkDistance = 'walkDistance',
    walkTime = 'walkTime',
    walkEnergy = 'walkEnergy',
    runDistance = 'runDistance',
    runTime = 'runTime',
    runEnergy = 'runEnergy',
    rightDistance = 'rightDistance',
    leftDistance = 'leftDistance',
    backwardDistance = 'backwardDistance',
    forwardDistance = 'forwardDistance',
    impacts = 'impacts',
    impactsZ1 = 'impactsZ1',
    impactsZ2 = 'impactsZ2',
    impactsZ3 = 'impactsZ3',
    jumps = 'jumps',
    jumpsZ1 = 'jumpsZ1',
    jumpsZ3 = 'jumpsZ3',
    jumpsZ2 = 'jumpsZ2',
    avgHRPercent = 'avgHRPercent',
    timeHRZ1 = 'timeHRZ1',
    timeHRZ2 = 'timeHRZ2',
    maxHRPercent = 'maxHRPercent',
    edwardsTL = 'edwardsTL',
    timeHRZ5 = 'timeHRZ5',
    timeHRZ4 = 'timeHRZ4',
    timeHRZ3 = 'timeHRZ3',
    tl = 'tl',
}

export enum OtherFitnessDataKeys {
    date = 'date',
    athlete = 'athlete',
    /* Values below probably doesn't makes sense in UnderSports. Remove or add them later */
    // category = 'category',
    // tags = 'tags',
    // notes = 'notes',
    // matchCycle = 'matchCycle',
    // lastMatchLabel = 'lastMatchLabel',
    // lastMatch = 'lastMatch',
    // nextMatch = 'nextMatch',
    // type = 'type',
    // starter = 'starter',
    // role = 'role',
    // nextMatchLabel = 'nextMatchLabel',
    // shortName = 'shortName',
    // jersey = 'jersey',
}

export const SupportedFitnessValues = {
    ...NumericFitnessDataKeys,
    ...OtherFitnessDataKeys,
};

export const supportedFitnessParams = Object.values(NumericFitnessDataKeys) as string[];

export type FitnessRecordValues = { [key in NumericFitnessDataKeys]?: number };

export type PlayerFitnessRecord = FitnessRecordValues & {
    playerId: string | null;
    [SupportedFitnessValues.athlete]: string;
};

export type PlayerEventFitnessRecord = PlayerFitnessRecord & {
    id: string;
    eventId: string | null;
    eventType: EventTypes.game | EventTypes.session;
    [SupportedFitnessValues.date]: Date;
};

export type TeamEventFitnessRecordData = {
    dataProvider: DataProvider;
    eventId: string | null;
    eventType: EventTypes.session | EventTypes.game;
    teamId: string;
    organizationId: string;
    author: Author;
    date: Date;
    totalDuration: number;
    players: Record<string, PlayerEventFitnessRecord>;
    playersIds: string[];
};

export type TeamEventFitnessRecord = TeamEventFitnessRecordData & {
    id: string;
};
