import { auth, db, functions } from 'common/firebase';
import { PlayerUpdateType } from 'entities/players/PlayerUpdates';
import { httpsCallable, HttpsCallableResult } from 'firebase/functions';
import Player, { PlayerData, PlayerStatus } from 'entities/players/Player';
import { mapPlayerFromDatesStrings } from 'entities/players/players.utils';
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore';
import { handleCollectionSnapshot, handleDocumentSnapshot, counter } from './utils';

export const getPlayerDocPath = (playerId: string) => {
    return `players/${playerId}`;
};

export const getPlayerStorageFilesPath = (playerId: string) => {
    return `players/${playerId}`;
};

export const getPlayerUpdatesCollectionPath = (playerId: string) => {
    return `players/${playerId}/playerUpdates`;
};

export const getPlayerUpdatesCollectionRef = (playerId: string) => {
    return collection(db, getPlayerUpdatesCollectionPath(playerId));
};

export const getPlayerDocRef = (playerId: string) => {
    return doc(db, `players/${playerId}`);
};

export const getPlayerSkillsDocPath = (playerId: string) => {
    return `players/${playerId}/metadata/skills`;
};

export const getPlayerSkillsDocRef = (playerId: string) => {
    const docPath = getPlayerSkillsDocPath(playerId);

    return doc(db, docPath);
};

const fetchPlayerByIdFunction = httpsCallable(functions, 'fetchPlayerByIdV2');
export const fetchPlayerById = ({
    playerId,
    organizationId,
}: {
    playerId: string;
    organizationId: string;
}) => {
    return fetchPlayerByIdFunction({ playerId, organizationId }).then((res) => {
        const { data } = res as HttpsCallableResult<{
            player: Player;
            status: 'success' | 'No-Access';
        }>;

        if (data.status !== 'success') {
            throw new Error(data.status);
        }

        counter(1);

        return mapPlayerFromDatesStrings(data.player);
    });
};

const fetchPlayersByIdsFunction = httpsCallable(functions, 'fetchPlayersByIdsV2');
export const fetchPlayersByIds = ({
    playersIds,
    organizationId,
}: {
    playersIds: string[];
    organizationId: string;
}) => {
    return fetchPlayersByIdsFunction({ playersIds, organizationId }).then((res) => {
        const { data } = res as HttpsCallableResult<{ players: Player[] }>;
        counter(data.players.length);

        return data.players.map(mapPlayerFromDatesStrings);
    });
};

const fetchScoutingListPlayersByIdsFunction = httpsCallable(
    functions,
    'fetchScoutingListPlayersByIdsV2',
);
export const fetchScoutingListPlayersByIds = ({
    playersIds,
    organizationId,
    listId,
}: {
    playersIds: string[];
    organizationId: string;
    listId: string;
}) => {
    return fetchScoutingListPlayersByIdsFunction({ playersIds, organizationId, listId }).then(
        (res) => {
            const { data } = res as HttpsCallableResult<{ players: Player[] }>;
            counter(data.players.length);

            return data.players.map(mapPlayerFromDatesStrings);
        },
    );
};

const fetchScoutingRecentPlayersListFunction = httpsCallable(
    functions,
    'fetchScoutingRecentPlayersV2',
);
export const fetchScoutingRecentPlayersList = ({ organizationId }: { organizationId: string }) => {
    return fetchScoutingRecentPlayersListFunction({ organizationId }).then((res) => {
        const { data } = res as HttpsCallableResult<{ players: Player[] }>;
        counter(data.players.length);

        return data.players.map(mapPlayerFromDatesStrings);
    });
};

const fetchScoutingNoRefPlayersFunction = httpsCallable(functions, 'fetchScoutingNoRefPlayersV2');
export const fetchScoutingNoRefPlayersList = ({ organizationId }: { organizationId: string }) => {
    return fetchScoutingNoRefPlayersFunction({ organizationId }).then((res) => {
        const { data } = res as HttpsCallableResult<{ players: Player[] }>;
        counter(data.players.length);

        return data.players.map(mapPlayerFromDatesStrings);
    });
};

export const fetchPlayerSkills = (playerId: string) => {
    const playerSkillsRef = getPlayerSkillsDocRef(playerId);

    return getDoc(playerSkillsRef).then(handleDocumentSnapshot);
};

const fetchScoutingClubPlayersListFunction = httpsCallable(functions, 'fetchScoutingClubPlayersV2');
export const fetchScoutingClubPlayersList = ({
    organizationId,
    clubId,
}: {
    organizationId: string;
    clubId: string;
}) => {
    return fetchScoutingClubPlayersListFunction({ organizationId, clubId }).then((res) => {
        const { data } = res as HttpsCallableResult<{ players: Player[] }>;
        counter(data.players.length);

        return data.players.map(mapPlayerFromDatesStrings);
    });
};

const fetchTeamPlayersFunction = httpsCallable(functions, 'fetchTeamPlayersV2');
export const fetchTeamPlayersList = ({
    organizationId,
    teamId,
}: {
    organizationId: string;
    teamId: string;
}) => {
    return fetchTeamPlayersFunction({ organizationId, teamId }).then((res) => {
        const { data } = res as HttpsCallableResult<{ players: Player[] }>;
        counter(data.players.length);

        return data.players.map(mapPlayerFromDatesStrings);
    });
};

const fetchAgentPlayersFunction = httpsCallable(functions, 'fetchAgentPlayersV2');
export const fetchAgentPlayersList = ({
    organizationId,
    agentId,
}: {
    organizationId: string;
    agentId: string;
}) => {
    return fetchAgentPlayersFunction({ organizationId, agentId }).then((res) => {
        const { data } = res as HttpsCallableResult<{ players: Player[] }>;
        counter(data.players.length);

        return data.players.map(mapPlayerFromDatesStrings);
    });
};

export const fetchPlayerUpdatesList = ({
    playerId,
    organizationId,
    updatesType,
}: {
    playerId: string;
    organizationId: string;
    updatesType?: PlayerUpdateType;
}) => {
    const playerUpdatesRef = getPlayerUpdatesCollectionRef(playerId);
    const restrictions = [where('organizationId', '==', organizationId)];

    if (updatesType) {
        restrictions.push(where('details.type', '==', updatesType));
    }

    const q = query(playerUpdatesRef, ...restrictions);

    return getDocs(q).then(handleCollectionSnapshot);
};

export const createPlayer = ({ playerData }: { playerData: PlayerData }) => {
    const playersRef = collection(db, 'players');
    const lastUpdateAuthorUid = auth.currentUser?.uid || null;
    Object.assign(playerData, { lastUpdateAuthorUid });

    return addDoc(playersRef, playerData).then((doc) => doc.id);
};

export const editPlayer = ({
    playerId,
    playerData,
}: {
    playerId: string;
    playerData: PlayerData;
}) => {
    const playerRef = doc(db, `players/${playerId}`);

    const lastUpdateAuthorUid = auth.currentUser?.uid || null;

    Object.assign(playerData, { lastUpdateAuthorUid });

    return updateDoc(playerRef, playerData);
};

export const removePlayer = ({ playerId }: { playerId: string }) => {
    const playerRef = doc(db, `players/${playerId}`);

    const lastUpdateAuthorUid = auth.currentUser?.uid || null;

    return updateDoc(playerRef, {
        status: PlayerStatus.deleted,
        lastUpdateAuthorUid,
    });
};

export type PlayerSearchResult =
    | {
          status: 'error';
      }
    | {
          status: 'success';
          playerId: 'new';
          playerData: Player;
          avatarStr: null | string;
      };

const getProfileDataFunction = httpsCallable(functions, 'getProfileDataV2');
export const getProfileData = ({
    pageUrl,
    organizationId,
}: {
    pageUrl: string;
    organizationId: string;
}) => {
    return getProfileDataFunction({ pageUrl, organizationId }).then((res) => {
        const result = res.data as PlayerSearchResult;

        if (result.status === 'error') {
            throw new Error();
        }

        return {
            playerId: result.playerId,
            playerData: mapPlayerFromDatesStrings(result.playerData),
            avatarStr: result.avatarStr,
        };
    });
};

export const scrapTeamByExternalLink = httpsCallable(functions, 'scrapTeamByExternalLink');

export const updatePlayerSkills = httpsCallable(functions, 'updatePlayerSkillsV2');
