import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

export const useMobileNavigation = () => {
    const { search, pathname } = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(search);
    const targetDevice = searchParams.get('targetDevice');

    useEffect(() => {
        if (targetDevice === 'mobileapp') {
            localStorage.setItem('targetDevice', 'mobileapp');
            searchParams.delete('targetDevice');
            navigate(`${pathname}?${searchParams.toString()}`);
        }
    }, [targetDevice]);

    return localStorage.getItem('targetDevice') === 'mobileapp';
};
