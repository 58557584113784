import { takeLatest, all, takeEvery, call, put, select } from 'redux-saga/effects';
import storageFilesActionTypes from './storageFiles.actionTypes';
import * as storageFilesActions from './storageFiles.actions';
import StorageFile, { StorageFileStatus } from './StorageFile';
import {
    createStorageFileRequest,
    fetchMethodologyStorageFilesRequest,
    fetchOrgStorageDirectFilesRequest,
    fetchOrgStorageFilesRequest,
    fetchPlayerStorageFilesRequest,
    fetchStorageFileRequest,
    fetchTeamStorageDirectFilesRequest,
    fetchTeamStorageFilesRequest,
    updateStorageFileRequest,
} from 'gateways/storageFilesGateway';
import User from 'entities/users/User';
import { formatISO } from 'date-fns';
import { getAuthor } from 'entities/users/users.utils';
import { userDataSelector } from 'entities/users/users.selectors';

export function* fetchStorageFileSaga({
    storageFileId,
    onSuccess,
    onFail,
}: ReturnType<typeof storageFilesActions.fetchStorageFile>) {
    try {
        const storageFile: StorageFile = yield call(fetchStorageFileRequest, {
            storageFileId,
        });

        if (storageFile) {
            yield put(storageFilesActions.storageFileReceived({ storageFile: storageFile }));
        }

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* fetchOrgStorageFilesSaga({
    organizationId,
    fromDate,
    onSuccess,
    onFail,
}: ReturnType<typeof storageFilesActions.fetchOrgStorageFiles>) {
    try {
        const storageFiles: StorageFile[] = yield call(fetchOrgStorageFilesRequest, {
            organizationId,
            fromDate,
        });

        yield put(storageFilesActions.storageFilesListReceived({ storageFiles }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* fetchOrgStorageDirectFilesSaga({
    organizationId,
    onSuccess,
    onFail,
}: ReturnType<typeof storageFilesActions.fetchOrgStorageDirectFiles>) {
    try {
        const storageFiles: StorageFile[] = yield call(fetchOrgStorageDirectFilesRequest, {
            organizationId,
        });

        yield put(storageFilesActions.storageFilesListReceived({ storageFiles }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* fetchTeamStorageFilesSaga({
    teamId,
    fromDate,
    targetType,
    onSuccess,
    onFail,
}: ReturnType<typeof storageFilesActions.fetchTeamStorageFiles>) {
    try {
        const storageFiles: StorageFile[] = yield call(fetchTeamStorageFilesRequest, {
            teamId,
            fromDate,
            targetType,
        });

        yield put(storageFilesActions.storageFilesListReceived({ storageFiles }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* fetchTeamStorageDirectFilesSaga({
    teamId,
    onSuccess,
    onFail,
}: ReturnType<typeof storageFilesActions.fetchTeamStorageFiles>) {
    try {
        const storageFiles: StorageFile[] = yield call(fetchTeamStorageDirectFilesRequest, {
            teamId,
        });

        yield put(storageFilesActions.storageFilesListReceived({ storageFiles }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* fetchMethodologyStorageFilesSaga({
    methodologyId,
    fromDate,
    onSuccess,
    onFail,
}: ReturnType<typeof storageFilesActions.fetchMethodologyStorageFiles>) {
    try {
        const storageFiles: StorageFile[] = yield call(fetchMethodologyStorageFilesRequest, {
            methodologyId,
            fromDate,
        });

        yield put(storageFilesActions.storageFilesListReceived({ storageFiles }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* fetchPlayerStorageFilesSaga({
    playerId,
    fromDate,
    onSuccess,
    onFail,
}: ReturnType<typeof storageFilesActions.fetchPlayerStorageFiles>) {
    try {
        const storageFiles: StorageFile[] = yield call(fetchPlayerStorageFilesRequest, {
            playerId,
            fromDate,
        });
        yield put(storageFilesActions.storageFilesListReceived({ storageFiles }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* createStorageFileSaga({
    file,
    storageFileId,
    filePath,
    target,
    targetType,
    onSuccess,
    onFail,
}: ReturnType<typeof storageFilesActions.createStorageFile>) {
    try {
        const user: User = yield select(userDataSelector);

        const storageFile: StorageFile = {
            id: storageFileId,
            displayName: file.name,
            size: file.size,
            contentType: file.type,
            date: formatISO(new Date()),
            description: '',
            author: getAuthor(user),
            status: StorageFileStatus.active,
            filePath,
            target,
            targetType,
        };

        yield call(createStorageFileRequest, {
            storageFile,
            storageFileId,
        });
        yield put(storageFilesActions.fetchStorageFile({ storageFileId }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* updateStorageFileSaga({
    storageFile,
    storageFileId,
    onSuccess,
    onFail,
}: ReturnType<typeof storageFilesActions.updateStorageFile>) {
    try {
        yield call(updateStorageFileRequest, {
            storageFile,
            storageFileId,
        });
        yield put(storageFilesActions.fetchStorageFile({ storageFileId }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

function* storageFileSaga() {
    yield all([
        takeLatest(storageFilesActionTypes.CREATE_STORAGE_FILE, createStorageFileSaga),
        takeLatest(storageFilesActionTypes.UPDATE_STORAGE_FILE, updateStorageFileSaga),
        takeEvery(storageFilesActionTypes.FETCH_STORAGE_FILE, fetchStorageFileSaga),
        takeLatest(storageFilesActionTypes.FETCH_ORG_STORAGE_FILES, fetchOrgStorageFilesSaga),
        takeLatest(
            storageFilesActionTypes.FETCH_ORG_STORAGE_DIRECT_FILES,
            fetchOrgStorageDirectFilesSaga,
        ),
        takeLatest(storageFilesActionTypes.FETCH_TEAM_STORAGE_FILES, fetchTeamStorageFilesSaga),
        takeLatest(
            storageFilesActionTypes.FETCH_TEAM_STORAGE_DIRECT_FILES,
            fetchTeamStorageDirectFilesSaga,
        ),
        takeLatest(
            storageFilesActionTypes.FETCH_METHODOLOGY_STORAGE_FILES,
            fetchMethodologyStorageFilesSaga,
        ),
        takeLatest(storageFilesActionTypes.FETCH_PLAYER_STORAGE_FILES, fetchPlayerStorageFilesSaga),
    ]);
}

export default storageFileSaga;
