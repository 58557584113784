import React, { useCallback, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { styled } from '@mui/material/styles';
import { AppConfig } from 'common/config/Config';
import WhatsAppBtn from 'common/components/WhatsAppBtn';
import { useCurrentUser } from 'entities/users/users.utils';
import { reportAppCrash } from 'gateways/errorsReportingGareway';
import { ErrorIcon, HomeIcon, LoopIcon, RefreshIcon, TelegramIcon } from 'common/icons';
import { CardBody, CardHeader, CardRoot } from 'common/entity-updates/CommonComponents';
import { Box, Button, Callout, CircularProgress, TelegramBtn, Typography } from 'common/components';
import Content from './Content';
import Sidebar from './Sidebar';

const ErrorPageBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    background: theme.custom.background.gradient,
    padding: theme.spacing(2),
}));

const ErrorPageCard = styled(CardRoot)(() => ({
    maxWidth: 400,
}));

const LayoutPage = styled('div')(({ theme }) => ({
    flexGrow: 1,
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
    height: '100%',
    overflow: 'hidden',
    background: theme.custom.background.gradient,
}));

type ErrorInfo = {
    message: string;
    stack: string;
};

type ErrorPageProps = {
    errorInfo: ErrorInfo | null;
};

const ErrorPage: React.FC<ErrorPageProps> = ({ errorInfo }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [errorSent, setErrorSent] = useState(false);
    const user = useCurrentUser();

    const handleSendReport = useCallback(async () => {
        if (!errorInfo) return;

        setIsLoading(true);

        try {
            await reportAppCrash({
                message: errorInfo.message,
                stack: JSON.stringify(errorInfo.stack, null, 4),
                pageHref: window.location.href,
            });
            toastr.success('🤝', t('errorPage.sendErrorReport.reportSentSuccess'));
        } catch (error) {
            toastr.error('🤯', t('errorPage.sendErrorReport.reportSentError'));
        }

        setIsLoading(false);
        setErrorSent(true);
    }, [errorInfo, user]);

    return (
        <ErrorPageBox>
            <ErrorPageCard>
                <CardHeader
                    title={t('errorPage.title')}
                    IconComponent={<LoopIcon sx={{ opacity: 0.7, width: 20, height: 20, mr: 1 }} />}
                />
                <CardBody sx={{ flexDirection: 'column', p: 1 }}>
                    <Callout sx={{ mb: 1 }} text={t('errorPage.newUpdates')} />
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        sx={{ mb: 1 }}
                        onClick={() => {
                            window.location.reload();
                        }}
                        startIcon={<RefreshIcon />}
                    >
                        {t('errorPage.reloadBtn')}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        sx={{ mb: 4 }}
                        fullWidth
                        startIcon={<HomeIcon />}
                        onClick={() => {
                            window.location.href = window.location.origin;
                        }}
                    >
                        {t('errorPage.goToHomePage')}
                    </Button>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                        {String(t('errorPage.contactUs'))}
                    </Typography>
                    <TelegramBtn sx={{ width: '100%', mb: 1 }} />
                    <WhatsAppBtn sx={{ width: '100%' }} />
                    {!errorSent && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                mt: 4,
                            }}
                        >
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                {t('errorPage.sendErrorReport.description')}
                            </Typography>
                            <Button
                                color="primary"
                                variant="contained"
                                fullWidth
                                disabled={isLoading}
                                startIcon={<ErrorIcon />}
                                onClick={handleSendReport}
                            >
                                {isLoading ? (
                                    <CircularProgress size={16} />
                                ) : (
                                    t('errorPage.sendErrorReport.btnText')
                                )}
                            </Button>
                        </Box>
                    )}
                </CardBody>
            </ErrorPageCard>
        </ErrorPageBox>
    );
};

type LayoutProps = {
    appConfig?: AppConfig;
};

const Layout: React.FC<LayoutProps> = ({ appConfig }) => {
    const [errorInfo, setErrorInfo] = useState<ErrorInfo | null>(null);

    if (appConfig?.maintenance) {
        return (
            <ErrorPageBox>
                <Typography sx={{ fontSize: 32 }} component="span" role="img">
                    🔧
                </Typography>
                <Typography sx={{ m: 2, maxWidth: 400 }}>
                    We are working on scheduled maintenance. Follow the updates in our Telegram
                    channel
                </Typography>
                <Button
                    component="a"
                    target="_blank"
                    variant="contained"
                    color="primary"
                    startIcon={<TelegramIcon />}
                    href="https://t.me/undersports_updates"
                >
                    Go to Telegram
                </Button>
            </ErrorPageBox>
        );
    }

    return (
        <Sentry.ErrorBoundary
            fallback={<ErrorPage errorInfo={errorInfo} />}
            onError={(error: any) => setErrorInfo({ message: error?.message, stack: error?.stack })}
        >
            <LayoutPage>
                <Sidebar />
                <Content />
            </LayoutPage>
        </Sentry.ErrorBoundary>
    );
};

export default Layout;
