import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { AddIcon } from 'common/icons';
import { SxProps } from '@mui/material';
import { requestUserAccessRecords } from 'entities/access/access.actions';
import { Button, Dialog, DialogContent, IconButton, Tooltip } from 'common/components';
import MethodologyForm from './MethodologyCreateBox';

type CreateMethodologyBtnProps = {
    showText?: boolean;
    noRedirect?: boolean;
    sx?: SxProps;
};

const CreateMethodologyBtn: React.FC<CreateMethodologyBtnProps> = ({
    showText,
    noRedirect,
    ...rest
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const handleSuccess = useCallback(
        (methodologyId: string) => {
            setIsDialogOpen(false);
            dispatch(requestUserAccessRecords());

            if (!noRedirect) {
                navigate(`/methodologies/${methodologyId}`);
            }
        },
        [noRedirect, navigate],
    );

    return (
        <>
            <Tooltip title={t('methodologyForm.tooltipText')}>
                {showText ? (
                    <Button onClick={() => setIsDialogOpen(true)} startIcon={<AddIcon />} {...rest}>
                        {t('methodologyForm.tooltipText')}
                    </Button>
                ) : (
                    <IconButton size="small" onClick={() => setIsDialogOpen(true)} {...rest}>
                        <AddIcon />
                    </IconButton>
                )}
            </Tooltip>
            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogContent>
                    <MethodologyForm
                        onSuccess={handleSuccess}
                        onClose={() => setIsDialogOpen(false)}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CreateMethodologyBtn;
