import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import AccessRecord, { AccessTypes } from 'entities/access/Access';
import { userAccessRecordsSelector } from 'entities/access/access.selectors';

export type PageParams = {
    teamId?: string;
    organizationId?: string;
    methodologyId?: string;
    playerId?: string;
    listId?: string;
};

const sortByTargetName = (a: AccessRecord, b: AccessRecord) =>
    a.targetName.localeCompare(b.targetName);

export const useUserLinks = () => {
    const { t } = useTranslation();
    const { teamId, organizationId, methodologyId, playerId } = useParams<PageParams>();
    const accessList = useSelector(userAccessRecordsSelector);

    const organizationsList = accessList.filter(
        (access) => access.accessType === AccessTypes.organization,
    );
    organizationsList.sort(sortByTargetName);

    const teamsList = accessList.filter((access) => access.accessType === AccessTypes.team);
    teamsList.sort(sortByTargetName);

    const playersList = accessList.filter((access) => access.accessType === AccessTypes.player);
    playersList.sort(sortByTargetName);

    const longlistsList = accessList.filter((access) => access.accessType === AccessTypes.longlist);
    longlistsList.sort(sortByTargetName);

    const methodologiesList = accessList.filter(
        (access) => access.accessType === AccessTypes.methodology,
    );
    methodologiesList.sort(sortByTargetName);

    const currentResourseName = useMemo(() => {
        const selectedTeam = teamsList.find(
            (accessRecord) => accessRecord.target.teamId === teamId,
        );
        const selectedOrg = organizationsList.find(
            (accessRecord) => accessRecord.target.organizationId === organizationId,
        );
        const selectedPlayer = playersList.find(
            (accessRecord) => accessRecord.target.playerId === playerId,
        );
        const selectedMethodology = methodologiesList.find(
            (accessRecord) => accessRecord.target.methodologyId === methodologyId,
        );

        return (
            selectedTeam?.targetName ||
            selectedOrg?.targetName ||
            selectedPlayer?.targetName ||
            selectedMethodology?.targetName ||
            t('accessList.openResourseBtn')
        );
    }, [organizationId, teamId, playerId, methodologyId, accessList]);

    return {
        organizationsList,
        teamsList,
        playersList,
        longlistsList,
        methodologiesList,
        currentResourseName,
    };
};
