import { Positions } from 'common/components/pitch/positionsCoordinates';

export type PlayerPositions = {
    [key in Positions]?: number;
};

export enum Foot {
    right = 'right',
    left = 'left',
    both = 'both',
}

export enum PlayerStatus {
    deleted = 'deleted',
    active = 'active',
}

export type SkillData = { value: number; max: number };

export type PlayerSkills = Record<string, SkillData>;

export type PlayerClub = {
    clubId: string | null;
    name: string;
    updatedDate: Date;
};

export type PlayerContract = {
    salary: number | null;
    expirationDate: Date | null;
    transferFee: number | null;
    notes: string;
};

export type TeamRecord = {
    active: boolean;
    organizationId: string;
};

export type OrgRecord = {
    active: boolean;
};

export type PlayerAgentAgencyRecord = {
    id: string;
    name: string;
};

export type PlayerAgentRecord = {
    id: string;
    name: string;
    agency: PlayerAgentAgencyRecord | null;
};

// used when create player | no id assigned yet
export type PlayerData = {
    firstName: string;
    lastName: string;
    middleName: string;
    enFirstName: string;
    enLastName: string;
    enMiddleName: string;
    playerNumber: string;
    dateOfBirth: Date;
    foot: Foot;
    weight: number | null;
    height: number | null;
    positions: PlayerPositions;
    filePath: string | null;
    countries: string[];
    email: string | null;
    tel: string | null;
    userId: string | null;
    status: PlayerStatus;
    profile: string[];
    grade: number | null;
    quality: number | null;
    potential: number | null;
    createDate: Date;
    teams: Record<string, TeamRecord>;
    organizations: Record<string, OrgRecord>;
    meta: {
        linkUserInvitationDate?: Date | null;
        linkUserInvitationAccepted?: boolean | null;
        linkUserInvitationAcceptedDate?: Date | null;
    };
    contract: PlayerContract;
    agent: PlayerAgentRecord | null;
    club: PlayerClub | null;
    references: Record<string, string>;
    prior: boolean;
    /* UID of the last user updated the player */
    lastUpdateAuthorUid?: string | null;
};

type Player = PlayerData & {
    id: string;
};

export default Player;
