import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Author } from 'entities/users/User';
import { styled, SxProps } from '@mui/material/styles';
import { Box, Tooltip, Typography } from 'common/components';

export const CardRoot = styled('section')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.custom.background.light,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
}));

export const CardHeaderRoot = styled('header')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.custom.background.light,
    padding: theme.spacing(0.5, 1),
    marginBottom: theme.spacing(0.5),
}));

export const CardBody = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
}));

export const ValueBox = styled(Box)(({ theme }) => ({
    flexBasis: 'calc(50% - 12px)',
    flexShrink: 0,
    flexGrow: 1,
    padding: theme.spacing(0.5, 1),
    textAlign: 'center',
    overflow: 'hidden',
    alignSelf: 'stretch',
}));

type CardHeaderProps = {
    IconComponent: React.ReactElement | string;
    date?: Date;
    author?: Author;
    title: string | null;
    subtitle?: string | null;
    sx?: SxProps;
};

export const CardHeader: React.FC<CardHeaderProps> = ({
    title,
    subtitle,
    author,
    date,
    IconComponent,
    ...rest
}) => {
    const { t } = useTranslation();
    const fullTime = date ? format(date, 'dd MMM yyyy HH:mm:ss') : '';
    const authorInfo = author ? `${author.name} | ${author.email}` : '';

    return (
        <CardHeaderRoot {...rest}>
            {IconComponent}
            <Tooltip title={title}>
                <Typography
                    noWrap
                    variant="body2"
                    sx={{ opacity: 0.7, mr: 1, flexGrow: 1 }}
                    align="left"
                >
                    {title}
                </Typography>
            </Tooltip>
            {author && (
                <Tooltip title={authorInfo}>
                    <Typography
                        noWrap
                        variant="body2"
                        sx={{ opacity: 0.7, mr: 1, width: 100, flexShrink: 0 }}
                    >
                        {author.name || t('common.noName')}
                    </Typography>
                </Tooltip>
            )}
            {date && (
                <Tooltip title={fullTime}>
                    <Typography
                        variant="body2"
                        sx={{ opacity: 0.7, width: 48, flexShrink: 0 }}
                        noWrap
                    >
                        {format(date, 'dd MMM')}
                    </Typography>
                </Tooltip>
            )}
            {subtitle && (
                <Tooltip title={fullTime}>
                    <Typography variant="body2" sx={{ opacity: 0.7, flexShrink: 0 }} noWrap>
                        {subtitle}
                    </Typography>
                </Tooltip>
            )}
        </CardHeaderRoot>
    );
};
