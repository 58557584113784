import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import { OrganizationIcon } from 'common/icons';
import SubmitButton from 'common/forms/SubmitButton';
import { createMethodology } from 'gateways/methodologiesGateway';
import { Box, Button, Input, Typography } from 'common/components';
import { requestUserAccessRecords } from 'entities/access/access.actions';
import DemoMethodologyBox from './DemoMethodologyBox';

type MethodologyCreateBoxProps = {
    onClose: () => void;
    onSuccess: (methodologyId: string) => void;
};

const MethodologyCreateBox: React.FC<MethodologyCreateBoxProps> = ({ onSuccess, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { isSubmitting, isValid },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
        },
    });

    const handleMethodologyCreate = useCallback(async ({ name }: { name: string }) => {
        const {
            data: { methodologyId },
        } = (await createMethodology({ methodologyName: name })) as {
            data: { methodologyId: string };
        };
        dispatch(requestUserAccessRecords());
        toastr.success('', t('methodologyForm.methodologyCreated'));
        onSuccess(methodologyId);
    }, []);

    return (
        <Box
            component="form"
            sx={{ display: 'flex', flexDirection: 'column', width: 260 }}
            onSubmit={handleSubmit(handleMethodologyCreate)}
        >
            <Typography variant="body2" sx={{ mb: 2 }}>
                {t('methodologyForm.description.one')}
            </Typography>
            <Typography variant="body2" sx={{ mb: 1, fontWeight: 600 }}>
                {t('methodologyForm.newMethodology.title')}
            </Typography>
            <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
                <OrganizationIcon />
                <Input
                    placeholder={t('methodologyForm.placeholder')}
                    sx={{ ml: 1, flexGrow: 1 }}
                    {...register('name', {
                        required: true,
                    })}
                />
            </Box>
            <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
                <Button sx={{ mr: 'auto' }} onClick={onClose} size="small">
                    {t('actions.close')}
                </Button>
                <SubmitButton
                    buttonText={t('methodologyForm.createMethodologyBtn') as string}
                    disabled={isSubmitting || !isValid}
                    isLoading={isSubmitting}
                    size="small"
                    sx={{ ml: 1 }}
                />
            </Box>
            <DemoMethodologyBox sx={{ mt: 'auto' }} />
        </Box>
    );
};

export default MethodologyCreateBox;
