import { useCallback, useEffect } from 'react';
import { RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import Game from 'entities/events/Game';
import _cloneDeep from 'lodash/cloneDeep';
import { selectors as eventSelectors } from 'entities/events';
import Team from './Team';
import { togglePlayerLabel } from './teams.utils';
import { PlayersLabelsMetadata } from './TeamPlayersLabels';
import {
    gameTypesSelector,
    playersLabelsSelector,
    teamDataSelector,
    teamsListSelector,
} from './teams.selectors';
import {
    requestGameTypes,
    requestPlayersLabels,
    teamDataRequest,
    teamsListRequest,
    updatePlayersLabels,
} from './teams.actions';

export const useTeam = (organizationId: string, teamId: string) => {
    const dispatch = useDispatch();
    const team = useSelector((state: RootState) => teamDataSelector(state, { teamId })) as
        | Team
        | undefined;

    useEffect(() => {
        if (!team) {
            dispatch(teamDataRequest({ organizationId, teamId }));
        }
    }, [team]);

    return team;
};

export const useTeams = (organizationId: string) => {
    const dispatch = useDispatch();
    const teamsList = useSelector((state: RootState) =>
        teamsListSelector(state, {
            organizationId,
        }),
    );

    useEffect(() => {
        if (teamsList.length === 0) {
            dispatch(teamsListRequest({ organizationId }));
        }
    }, [teamsList.length]);

    return teamsList;
};

const defaultPlayersLabelsMetadata: PlayersLabelsMetadata = {
    playersLabelsList: [],
    playersMapping: {},
};

export const usePlayersLabelsMetadata = (organizationId: string, teamId: string) => {
    const dispatch = useDispatch();
    const playersLabelsMetadata =
        useSelector((state: RootState) => playersLabelsSelector(state, { teamId })) ||
        (defaultPlayersLabelsMetadata as PlayersLabelsMetadata);

    useEffect(() => {
        dispatch(requestPlayersLabels({ teamId, organizationId }));
    }, [teamId, organizationId]);

    const handleToggleLabel = useCallback(
        (labelId: string, playerId: string) => {
            const updatedLabelsIds = togglePlayerLabel(playersLabelsMetadata, playerId, labelId);
            const updatedLabelsMetadata = _cloneDeep(playersLabelsMetadata);
            Object.assign(updatedLabelsMetadata.playersMapping, {
                [playerId]: { labels: updatedLabelsIds },
            });

            dispatch(
                updatePlayersLabels({
                    organizationId,
                    teamId,
                    playersLabelsMetadata: updatedLabelsMetadata,
                }),
            );
        },
        [playersLabelsMetadata, organizationId, teamId, dispatch],
    );

    return { playersLabelsMetadata, onTogglePlayerLabel: handleToggleLabel };
};

export const usePlayerLabels = ({
    organizationId,
    teamId,
    playerId,
}: {
    organizationId: string;
    teamId: string;
    playerId: string;
}) => {
    const { playersLabelsMetadata } = usePlayersLabelsMetadata(organizationId, teamId);
    const { playersLabelsList, playersMapping } = playersLabelsMetadata;
    const labelsMap = Object.fromEntries(playersLabelsList.map((label) => [label.id, label]));
    const playerLabels = (playersMapping[playerId]?.labels || [])
        .map((label) => labelsMap[label])
        .filter(Boolean);

    return playerLabels;
};

export const useGameTypes = (organizationId: string, teamId: string) => {
    const dispatch = useDispatch();

    const gameTypes = useSelector((state: RootState) => gameTypesSelector(state, { teamId }));

    useEffect(() => {
        dispatch(requestGameTypes({ teamId, organizationId }));
    }, [teamId, organizationId]);

    return { gameTypes };
};

export const useGameType = ({
    organizationId,
    teamId,
    gameId,
}: {
    organizationId: string;
    teamId: string;
    gameId: string;
}) => {
    const { gameTypes } = useGameTypes(organizationId, teamId);

    const game = useSelector((state: RootState) =>
        eventSelectors.eventDataSelector(state, { eventId: gameId }),
    ) as Game;

    const gameTypeLabel = gameTypes.find(
        (gameTypeLabel) => gameTypeLabel.id === game.details.gameType,
    );

    return gameTypeLabel?.isActive ? gameTypeLabel : null;
};

export const useTeamLinks = (organizationId: string, teamId: string) => {
    const team = useTeam(organizationId, teamId);

    return team?.links || [];
};
