import Report, { ReportData, ReportParams } from './Report';
import actionTypes from './reports.actionTypes';

export const reportDataReceived = ({
    reportData,
    teamId,
}: {
    reportData: Report;
    teamId: string;
}) => ({
    type: actionTypes.REPORT_DATA_RECEIVED,
    reportData,
    teamId,
});

export const reportsListRequest = ({
    teamId,
    organizationId,
    onFinish,
}: {
    teamId: string;
    organizationId: string;
    onFinish?: VoidFunction;
}) => ({
    type: actionTypes.REPORTS_LIST_REQUEST,
    teamId,
    organizationId,
    onFinish,
});

export const reportsByAuthorRequest = ({
    teamId,
    organizationId,
    userId,
    count,
    fromDate,
    toDate,
    onFinish,
}: {
    teamId: string;
    organizationId: string;
    userId: string;
    count?: number;
    fromDate?: Date;
    toDate?: Date;
    onFinish?: VoidFunction;
}) => ({
    type: actionTypes.REPORTS_BY_AUTHOR_REQUEST,
    teamId,
    organizationId,
    userId,
    count,
    fromDate,
    toDate,
    onFinish,
});

export const reportsListReceived = ({
    reportsList,
    teamId,
}: {
    reportsList: Report[];
    teamId: string;
}) => ({
    type: actionTypes.REPORTS_LIST_RECEIVED,
    reportsList,
    teamId,
});

export const reportDataUpdate = ({
    eventId,
    teamId,
    organizationId,
    reportData,
    onSuccess,
}: ReportParams & {
    reportData: ReportData;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.REPORT_UPDATE_REQUEST,
    eventId,
    teamId,
    organizationId,
    reportData,
    onSuccess,
});

export const reportDataRequest = ({ eventId, teamId, organizationId }: ReportParams) => ({
    type: actionTypes.REPORT_DATA_REQUEST,
    eventId,
    teamId,
    organizationId,
});

export const playersForMatchReportRequest = ({
    matchReportId,
    organizationId,
}: {
    matchReportId: string;
    organizationId: string;
}) => ({
    type: actionTypes.PLAYERS_FOR_MATCH_REPORT_REQUEST,
    matchReportId,
    organizationId,
});

export const removeReportFromList = ({ eventId }: { eventId: string }) => ({
    type: actionTypes.REMOVE_REPORT_FROM_LIST,
    eventId,
});

export const removeReport = ({
    eventId,
    organizationId,
    teamId,
    onSuccess,
}: {
    eventId: string;
    organizationId: string;
    teamId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.REMOVE_REPORT,
    eventId,
    organizationId,
    teamId,
    onSuccess,
});
