import { Button, Typography } from 'common/components';
import { styled } from '@mui/material/styles';
import { selectors as authSelectors } from 'features/auth';
import { AuthState } from 'features/auth/Auth';
import EmailNotVerified from 'features/auth/email-verification/EmailNotVerified';
import LoginPage from 'features/auth/login/LoginPage';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router';
import AnimatedBackground from './AnimatedBackground';
import KickStart from './KickStart';
import { VideoTutorial } from 'common/components/VideoPlayBtn/VideoTutorial';
import InvitationsList from './InvitationsList';
import UserLinksBtn from 'common/layout/UserLinksBtn';
import { TutorialName } from 'common/components/VideoPlayBtn/videoTutorial.config';
import ChooseLanguage from 'features/profile/components/ChooseLanguage';
import UserResourses from './UserResourses';

const PREFIX = 'MainPage';

const classes = {
    title: `${PREFIX}-title`,
    word: `${PREFIX}-word`,
    row: `${PREFIX}-row`,
    quickTools: `${PREFIX}-quickTools`,
    toolBtn: `${PREFIX}-toolBtn`,
    bottomSection: `${PREFIX}-bottomSection`,
    hint: `${PREFIX}-hint`,
    questionMark: `${PREFIX}-questionMark`,
};

const StyledMainPage = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(8),
    position: 'relative',

    [theme.breakpoints.up('md')]: {
        minHeight: '100%',
    },

    [`& .${classes.title}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        opacity: 0.8,
        margin: 'auto',
        padding: theme.spacing(3),
    },

    [`& .${classes.word}`]: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
        margin: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(0, 1),
    },

    [`& .${classes.row}`]: {
        display: 'flex',
        margin: 'auto',
        width: '100%',
        flexDirection: 'column-reverse',

        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },

    [`& .${classes.quickTools}`]: {
        display: 'none',
        margin: theme.spacing(2),

        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            marginLeft: 'auto',
            flexDirection: 'column',
        },
    },

    [`& .${classes.toolBtn}`]: {
        position: 'relative',
        marginBottom: theme.spacing(1),
    },

    [`& .${classes.bottomSection}`]: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },

    [`& .${classes.hint}`]: {
        maxWidth: '25%',
        margin: theme.spacing(3, 3, 4),

        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },

    [`& .${classes.questionMark}`]: {
        borderRadius: '50%',
        border: '2px solid rgba(0, 0, 0, 0.54)',
        color: 'rgba(0, 0, 0, 0.54)',
        width: 20,
        height: 20,
        fontSize: 13,
        fontWeight: 600,
        padding: theme.spacing(0, 0.5),
    },
}));

const MainPage: React.FC = () => {
    const { t } = useTranslation();
    const authState = useSelector(authSelectors.authStateSelector);

    return (
        <AnimatedBackground>
            <StyledMainPage>
                <UserLinksBtn sx={{ position: 'absolute', top: 16, right: { xs: 8, sm: 16 } }} />
                <InvitationsList sx={{ m: 1 }} />
                <UserResourses sx={{ mb: 2 }} />
                <div className={classes.row}>
                    {authState !== AuthState.notVerified && (
                        <Typography variant="h3" className={classes.title}>
                            <span className={classes.word}>Learn</span>
                            <span className={classes.word}>Educate</span>
                            <span className={classes.word}>Win</span>
                        </Typography>
                    )}
                    {[null, AuthState.notAuthenticated].includes(authState) && <LoginPage />}
                    {authState === AuthState.notVerified && <EmailNotVerified />}
                    {authState === AuthState.verified && <KickStart />}
                </div>
                {[null, AuthState.notAuthenticated].includes(authState) && (
                    <VideoTutorial tutorialName={TutorialName.createAccaunt} />
                )}
                <ChooseLanguage sx={{ mt: 2 }} />
                <div className={classes.bottomSection}>
                    <Typography variant="body1" className={classes.hint}>
                        👈 Use{' '}
                        <Typography className={classes.questionMark} variant="caption">
                            ?
                        </Typography>{' '}
                        button at the bottom of the Side Panel for more information about the opened
                        page
                    </Typography>
                    <div className={classes.quickTools}>
                        <Typography align="center" gutterBottom>
                            {t('mainPage.quickTools')}
                        </Typography>
                        <Button
                            className={classes.toolBtn}
                            variant="outlined"
                            component={Link}
                            to="/tactic-board"
                        >
                            {t('mainPage.tacticBoard')}
                        </Button>
                        {authState === AuthState.verified && (
                            <Button
                                className={classes.toolBtn}
                                variant="outlined"
                                component={Link}
                                to="/challenges"
                            >
                                {t('mainPage.challenges')}
                            </Button>
                        )}
                    </div>
                </div>
            </StyledMainPage>
        </AnimatedBackground>
    );
};

export default MainPage;
