import 'regenerator-runtime/runtime';
import 'core-js/stable';
import React from 'react';
import { Provider } from 'react-redux';
import { setDefaultOptions } from 'date-fns';
import { BrowserRouter } from 'react-router';
import { I18nextProvider } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import i18n from './i18n';
import Root from './Root';
import store from './store';
import theme from './theme';
import 'video.js/dist/video-js.css';
import 'react-quill-new/dist/quill.snow.css';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './index.css';

Sentry.init({
    dsn: 'https://d00c7aef8ef59bdfc1378a807578fffc@o4508132633280512.ingest.de.sentry.io/4508132636885072',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/undersports\.club/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

setDefaultOptions({ weekStartsOn: 1 });

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <I18nextProvider i18n={i18n}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Provider store={store}>
                        <CssBaseline />
                        <Root />
                    </Provider>
                </BrowserRouter>
            </ThemeProvider>
        </LocalizationProvider>
    </I18nextProvider>,
);
