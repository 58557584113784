import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Typography, Box, Button } from 'common/components';
import VerificationSuggestions from './VerificationSuggestions';
import { requestSignOut } from '../auth.actions';

type EmailNotVerifiedProps = {
    sx?: any;
};

const EmailNotVerified: React.FC<EmailNotVerifiedProps> = ({ sx }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSignOut = useCallback(() => {
        navigate('/');
        dispatch(requestSignOut());
    }, []);

    return (
        <Box
            sx={{
                m: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 4,
                maxWidth: 400,
                // overflowY: { xs: 'auto', sm: 'visible' },
                overflowY: 'auto',
                ...sx,
            }}
        >
            <Box component="span" sx={{ my: 2, mx: 'auto', fontSize: '100px' }}>
                📧
            </Box>
            <Typography sx={{ width: 300 }} variant="body1">
                {t('auth.emailNotVerified')}
            </Typography>
            <VerificationSuggestions />
            <Typography sx={{ width: 300, mt: 2 }} variant="body2">
                {t('auth.emailVerification.alreadyVerified')}
            </Typography>
            <Button
                variant="contained"
                sx={{ my: 1 }}
                color="primary"
                size="small"
                onClick={handleSignOut}
            >
                {t('auth.signOut')}
            </Button>
        </Box>
    );
};

export default EmailNotVerified;
