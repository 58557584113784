import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Box, Button, Drawer, useMediaQuery, useTheme } from 'common/components';

export const DialogHeader = styled('header')(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2),
    backgroundColor: theme.custom.background.light,
    borderBottom: '1px solid lightgrey',
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    ['& .MuiDrawer-paper']: {
        background: theme.custom.background.gradient,
    },
    [`& .MuiBackdrop-root`]: {
        backgroundColor: 'rgba(32, 38, 45, 0.2)',
        backdropFilter: 'blur(2px)',
    },
}));

type SlideDialogWithCloseBtnProps = {
    isOpen: boolean;
    children: any;
    noPaddings?: boolean;
    closeByClickOnly?: boolean;
    sx?: any;
    onClose: VoidFunction;
};

const SlideDialogWithCloseBtn: React.FC<SlideDialogWithCloseBtnProps> = ({
    isOpen,
    children,
    noPaddings = false,
    sx = {},
    closeByClickOnly,
    onClose,
    ...rest
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isFullScreen = useMediaQuery(theme.breakpoints.only('xs'));
    const { maxWidth, ...restSx } = sx;

    return (
        <StyledDrawer
            anchor="right"
            onClose={closeByClickOnly ? undefined : onClose}
            open={isOpen}
            {...rest}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    px: noPaddings ? 0 : 1,
                    py: noPaddings ? 0 : 2,
                    flexGrow: 1,
                    overflowY: 'auto',
                    maxWidth: isFullScreen ? '100vw' : maxWidth || 450,
                    minWidth: isFullScreen ? '100vw' : 300,
                    ...restSx,
                }}
            >
                {children}
            </Box>
            <Box
                sx={{
                    py: 1,
                    px: 3,
                    boxShadow: (theme) => theme.custom.boxShadow.main,
                }}
            >
                <Button color="inherit" onClick={onClose}>
                    {t('actions.close')}
                </Button>
            </Box>
        </StyledDrawer>
    );
};

export default SlideDialogWithCloseBtn;
