import { takeLatest, all, takeEvery, call, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import * as reportsGateway from 'gateways/reportsGateway';
import { actions as snackbarActions } from 'common/snackbar';
import reportsActionTypes from './reports.actionTypes';
import * as reportsActions from './reports.actions';
import Report, { ReportData, ReportParams } from './Report';
import Player from 'entities/players/Player';
import { playersListReceived } from 'entities/players/players.actions';

export function* fetchReportDataSaga({
    organizationId,
    teamId,
    eventId,
}: ReturnType<typeof reportsActions.reportDataRequest>) {
    try {
        const reportData: Report = yield call(reportsGateway.fetchReportById, {
            organizationId,
            teamId,
            eventId,
        });
        if (reportData) {
            yield put(reportsActions.reportDataReceived({ reportData, teamId }));
        }
    } catch (e) {
        console.error({ _error: `Failed to fetch report for event ${eventId}` }, e);
    }
}

export function* fetchPlayersForMatchReportSaga({
    organizationId,
    matchReportId,
}: ReturnType<typeof reportsActions.playersForMatchReportRequest>) {
    try {
        const matchReportPlayers: Player[] = yield call(reportsGateway.fetchPlayersForMatchReport, {
            organizationId,
            matchReportId,
        });

        yield put(playersListReceived(matchReportPlayers));
    } catch (e) {
        console.error({ _error: `Failed to fetch players for match report  ${matchReportId}` }, e);
    }
}

export function* fetchReportsListSaga({
    teamId,
    organizationId,
    onFinish,
}: ReturnType<typeof reportsActions.reportsListRequest>) {
    try {
        const reportsList: Report[] = yield call(reportsGateway.fetchReportsList, {
            teamId,
            organizationId,
        });
        yield put(reportsActions.reportsListReceived({ reportsList, teamId }));
    } catch (e) {
        console.error({
            e,
            _error: `Failed to fetch reports for team ${teamId} org ${organizationId}`,
        });
    }

    if (onFinish) {
        yield call(onFinish);
    }
}

export function* fetchReportsByAuthorSaga({
    teamId,
    organizationId,
    userId,
    count,
    fromDate,
    toDate,
    onFinish,
}: ReturnType<typeof reportsActions.reportsByAuthorRequest>) {
    try {
        const reportsList: Report[] = yield call(reportsGateway.fetchReportsByAuthor, {
            teamId,
            organizationId,
            userId,
            count,
            fromDate,
            toDate,
        });
        yield put(reportsActions.reportsListReceived({ reportsList, teamId }));
    } catch (e) {
        console.log(e);
        yield call(
            toastr.error,
            'Failed to fetch reports',
            "Internet connection can be unstable or you don't have permissions to do it",
        );
        console.error({
            e,
            _error: `Failed to fetch reports for team ${teamId} org ${organizationId}`,
        });
    }

    if (onFinish) {
        yield call(onFinish);
    }
}

export function* createReportSaga({
    reportData,
    organizationId,
    teamId,
}: Pick<ReportParams, 'organizationId' | 'teamId'> & { reportData: ReportData }) {
    try {
        const id: string = yield call(reportsGateway.createReport, {
            organizationId,
            teamId,
            reportData,
        });
        return id;
    } catch (e) {
        yield put(
            snackbarActions.showError({
                message: '🤕 Failed to create the game. Reload page and try one more time please',
            }),
        );
        throw e;
    }
}

export function* updateReportSaga({
    eventId,
    organizationId,
    teamId,
    reportData,
    onSuccess,
}: ReturnType<typeof reportsActions.reportDataUpdate>) {
    try {
        yield call(reportsGateway.updateReportData, {
            eventId,
            organizationId,
            teamId,
            reportData,
        });
        yield put(
            reportsActions.reportDataRequest({
                organizationId,
                teamId,
                eventId,
            }),
        );
        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        yield call(console.error, e);
    }
}

export function* removeReportsSaga({
    organizationId,
    teamId,
    eventId,
    onSuccess,
}: ReturnType<typeof reportsActions.removeReport>) {
    try {
        yield call(reportsGateway.removeReport, { organizationId, teamId, eventId });
        yield put(reportsActions.removeReportFromList({ eventId }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        yield call(
            toastr.error,
            '🤕 Failed to delete report',
            'Reload page and try one more time please',
        );
        throw e;
    }
}

function* reportsSaga() {
    yield all([
        takeLatest(reportsActionTypes.REPORT_UPDATE_REQUEST, updateReportSaga),
        // takeLatest(reportsActionTypes.REPORT_CREATE_REQUEST, createReportSaga),
        takeEvery(reportsActionTypes.REPORT_DATA_REQUEST, fetchReportDataSaga),
        takeEvery(
            reportsActionTypes.PLAYERS_FOR_MATCH_REPORT_REQUEST,
            fetchPlayersForMatchReportSaga,
        ),
        takeLatest(reportsActionTypes.REPORTS_LIST_REQUEST, fetchReportsListSaga),
        takeLatest(reportsActionTypes.REPORTS_BY_AUTHOR_REQUEST, fetchReportsByAuthorSaga),
        takeLatest(reportsActionTypes.REMOVE_REPORT, removeReportsSaga),
    ]);
}

export default reportsSaga;
