import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { createSelector } from 'reselect';
import { RootState } from 'store';
import AccessRecord, { AccessTarget, AccessTypes } from './Access';
import { userDataSelector } from 'entities/users/users.selectors';

export const accessHashSelector = (state: RootState) => state.entities.access.accessHash;

export const defaultPermissionsSelector = (state: RootState) =>
    state.entities.access.defaultPermissions;

export const accessRecordsSelector = createSelector(accessHashSelector, (accessHash) => {
    const accessRecords: AccessRecord[] = Object.values(accessHash);
    return accessRecords;
});

export const accessDataSelector = createSelector(
    accessHashSelector,
    (_: any, { gid }: { gid: string }) => gid,
    (accessHash, gid) => _get(accessHash, gid),
);

export const accessListForResourceSelector = createSelector(
    accessHashSelector,
    (_: any, { target }: { target: AccessTarget }) => target,
    (accessHash, target) => {
        const accessList: AccessRecord[] = Object.values(accessHash);
        return accessList.filter((access) => _isEqual(access.target, target));
    },
);

export const methodologyRolesListSelector = createSelector(
    accessHashSelector,
    (_: any, { methodologyId }: { methodologyId: string }) => methodologyId,
    (accessHash, methodologyId) => {
        const accessList: AccessRecord[] = Object.values(accessHash);
        return accessList.filter((access) => access.target.methodologyId === methodologyId);
    },
);

export const userAccessRecordsSelector = createSelector(
    accessRecordsSelector,
    userDataSelector,
    (accessRecords, user) => {
        if (!user) return [];
        return accessRecords.filter((accessRecord) => accessRecord.userId === user.id);
    },
);

export const userMethodologiesSelector = createSelector(userAccessRecordsSelector, (accessList) => {
    const methodologiesAccesses = accessList.filter(
        (access) => access.accessType === AccessTypes.methodology,
    );
    return methodologiesAccesses;
});
