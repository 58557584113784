export enum Permissions {
    /* Org */
    canMovePlayersBetweenTeams = 'organization.movePlayers.write',
    canWriteOrgSettings = 'organization.settings.write',
    canWriteOrgAccess = 'organization.access.write',
    canReadOrgPage = 'organization.dashboard.read',
    canWriteOrgLinks = 'organization.links.write',
    canReadOrgLinks = 'organization.links.read',
    canReadOrgFiles = 'organization.files.read',
    canWriteOrgFiles = 'organization.files.write',
    canUsePlayersFromOtherTeams = 'organization.players.useForEventsOtherTeams',

    /* Team */
    canWriteTeamSettings = 'team.settings.write',
    canReadTeamDashboard = 'team.dashboard.read',
    canReadTeamPlayersPage = 'team.playersList.read',
    canReadTeamEvaluationsPage = 'team.evaluations.read',
    canReadTeamAttendancePage = 'team.attendance.read',
    canReadTeamConditionsPage = 'team.conditions.read',
    canReadTeamDoctorPage = 'team.doctorPage.read',
    canReadTeamAssistant = 'team.assistant.read',
    canReadTeamStats = 'team.stats.read',
    canReadTeamCalendar = 'team.calendar.read',
    canReadTeamAIReports = 'team.aiReports.read',
    canReadTeamInjuries = 'team.injuries.read',
    canWriteTeamInjuries = 'team.injuries.write',
    canWriteTeamConditions = 'team.conditions.write',
    canWriteEvaluations = 'team.evaluations.write',
    canCopyEvents = 'team.calendar.copyEvents',
    canCreateTeam = 'team.create',
    canDeleteTeam = 'team.delete',
    canArchiveTeam = 'team.archive',
    canUnarchiveTeam = 'team.unarchive',
    canWriteTeamAccess = 'team.access.write',
    canReadTeamFiles = 'team.files.read',
    canWriteTeamFiles = 'team.files.write',
    canReadTeamLinks = 'team.links.read',
    canWriteTeamLinks = 'team.links.write',
    canWriteGamesSessions = 'team.calendar.gamesSessions.write',
    isTeamMember = 'team.member',
    canWriteEvents = 'team.calendar.events.write',
    canReadPlayerUpdatesHistory = 'team.playersUpdates.read',
    canReadGamesDetails = 'team.calendar.gamesDetails.read',
    canReadSessionsDetails = 'team.calendar.sesisonsDetails.read',
    canReadRegularEventsDetails = 'team.calendar.eventsDetails.read',
    canReadTeamUpdatesHistory = 'team.teamUpdates.read',
    canWriteMedicalTests = 'team.medicalTests.write',
    canWriteFitnessData = 'team.fitnessData.write',

    /* Player */
    hasPlayerMedicalPageAccess = 'player.medicalRecords.edit',
    canReadPlayerProfile = 'player.profile.read',
    canWritePlayerProfile = 'player.profile.write',
    canWritePlayerAccess = 'player.access.write',

    /* Methodology */
    canReadMethodology = 'methodology.read',
    canWriteMethodology = 'methodology.write',
    canDeleteMethodology = 'methodology.delete',
    canWriteMethodologySettings = 'methodology.settings.write',
    canWriteMethodologyAccess = 'methodology.access.write',

    /* Drills */
    canWriteDrills = 'methodology.drills.write',
    canReadDrills = 'methodology.drills.read',

    /* Scouting - too complicated. Will do later */
    canReadScoutingLists = 'scouting.lists.read',
    canReadScoutDashboard = 'scouting.ui.scoutDashboard',
    canWriteScoutingClubsList = 'scouting.clubs.write',
    canReadLonglistsFolders = 'scouting.lists.folders.read',
    canWriteLonglistsFolders = 'scouting.lists.folders.write',
    canReadScoutingPlayers = 'scouting.players.profile.read',
    canWriteScoutingPlayers = 'scouting.players.profile.write',
    /*
    canOpenClubsPage =              'scouting.ui.clubs',
    canOpenRegionsPage =            'scouting.ui.regions',
    canOpenMatchReportsPage =       'scouting.ui.matchReports',
    canReadMatchReports =           'scouting.matchReports.read',
    canReadOwnMatchReports =        'scouting.matchReports.readOwn',
    canReadScoutingClubs =          'scouting.clubs.read',
    canWriteScoutingClubs =         'scouting.clubs.write',
    canCreateLonglist =             'scouting.lists.create',
    canReadScoutingLists =          'scouting.lists.read',
    canReadOwnScoutingLists =       'scouting.lists.readOwn',
    canWriteScoutingLists =         'scouting.lists.write',
    canWriteListAccess =            'scouting.lists.access.write',
    canNotifyClients =              'scouting.lists.notifyClients',
    canReadAgents =                 'scouting.agents.read',
    canCreatePlayers =              'scouting.players.create',
    canReadPlayersReports =         'scouting.players.reports.read',
    canReadOwnPlayersReports =      'scouting.players.reports.readOwn',
    canWritePlayersReports =        'scouting.players.reports.write',
    canReadPlayersScores =          'scouting.players.scores.read',
    canWritePlayersScores =         'scouting.players.scores.write',
    */
}
