import { createTheme } from '@mui/material/styles';
import { green, red, blue, blueGrey, orange } from '@mui/material/colors';

declare module '@mui/material/styles' {
    interface Theme {
        custom: {
            background: {
                gradient: string;
                main: string;
                light: string;
                lightBlue: string;
                blue: string;
                blueCard: string;
            };
            boxShadow: {
                main: string;
                secondary: string;
            };
            divider: string;
            colors: {
                red: {
                    light: string;
                    main: string;
                    dark: string;
                };
                orange: {
                    light: string;
                    main: string;
                    dark: string;
                };
                green: {
                    light: string;
                    main: string;
                    dark: string;
                };
            };
        };
    }
    /* allow configuration using `createTheme` */
    interface ThemeOptions {
        custom: {
            background: {
                gradient: string;
                blueCard: string;
            };
            boxShadow: {
                main: string;
                secondary: string;
            };
            divider: string;
            colors: {
                red: {
                    light: string;
                    main: string;
                    dark: string;
                };
                orange: {
                    light: string;
                    main: string;
                    dark: string;
                };
                green: {
                    light: string;
                    main: string;
                    dark: string;
                };
            };
        };
    }
}

const config = {
    custom: {
        background: {
            light: 'rgba(0, 0, 0, 0.04)',
            main: 'rgba(0, 0, 0, 0.1)',
            gradient:
                'linear-gradient( 113.81deg, rgba(255, 242, 248, 1) 0%, rgba(243, 241, 255, 1) 51.04%, rgba(241, 248, 255, 1) 100%)',
            lightBlue:
                'linear-gradient(0deg, rgba(33, 150, 243, 0.12), rgba(33, 150, 243, 0.12)), #FFFFFF',
            blue: 'rgba(207,230,252,255)',
            blueCard: 'rgba(33, 150, 243, 0.12)',
        },
        boxShadow: {
            main: 'rgba(0, 0, 0, 0.1) 0px 0px 7px',
            secondary: '0px 1px 2px rgba(0, 0, 0, 0.25)',
        },
        divider: '#e7e4ea',
        colors: {
            red: {
                light: red[200],
                main: red[500],
                dark: red[800],
            },
            orange: {
                light: orange[200],
                main: orange[500],
                dark: orange[800],
            },
            green: {
                light: green[200],
                main: green[500],
                dark: green[800],
            },
        },
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiTooltip: {
            defaultProps: {
                disableInteractive: true,
            },
        },
    },
    props: {
        MuiTypography: {
            variantMapping: {
                h1: 'h1',
                h2: 'h2',
                h3: 'h2',
                h4: 'h2',
                h5: 'h2',
                h6: 'h2',
                subtitle1: 'h3',
                subtitle2: 'h3',
                body1: 'p',
                body2: 'p',
            },
        },
    },
    typography: {
        boxSizing: 'border-box',
        fontFamily: 'Montserrat',
        h1: {
            // 18px
            fontSize: '1.125rem',
            fontWeight: 600,
        },
        h2: {
            // 16px
            fontSize: '1rem',
            fontWeight: 500,
        },
        subtitle1: {
            // 16px
            fontSize: '1rem',
            fontWeight: 500,
            lineHeight: '1.25rem',
        },
        subtitle2: {
            // 14px
            fontSize: '0.875rem',
            fontWeight: 500,
        },
        body1: {
            // 14px
            fontSize: '0.875rem',
            fontWeight: 500,
        },
        body2: {
            // 12px
            fontSize: '0.75rem',
            fontWeight: 500,
        },
        caption: {
            // 10px
            fontSize: '0.625rem',
            fontWeight: 600,
        },
        overline: {
            // 8px
            fontSize: '0.5rem',
            fontWeight: 600,
        },
    },
    palette: {
        primary: blue,
        secondary: blueGrey,
        error: red,
    },
};

export default createTheme(config);
