import React, { lazy, Suspense, useMemo } from 'react';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { styled } from '@mui/material/styles';
import { AuthState } from 'features/auth/Auth';
import MainPage from 'features/home/components/MainPage';
import { selectors as authSelectors } from 'features/auth';
import { LoadingLine, NotFoundPage } from 'common/components';
import { useCustomAuthToken } from './useCustomAuthToken';
import { useMobileNavigation } from './useMobileNavigation';

const StylesSpinner = styled(LoadingLine)({
    width: '100vw',
    display: 'flex',
    overflow: 'hidden',
});

const MainContent = styled('main')<{ isMobileApp: boolean }>(({ theme, isMobileApp }) => ({
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
    // for overlay to hide section on mobile
    position: 'relative',
    marginTop: isMobileApp ? 0 : theme.spacing(6),
    height: isMobileApp ? '100vh' : 'calc(100vh - 48px)',

    [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(0),
        height: '100vh',
    },
}));

const ProfilePage = lazy(
    () => import(/* webpackChunkName: 'ProfilePage' */ 'features/profile/ProfilePage'),
);

const TestPage = lazy(
    () => import(/* webpackChunkName: 'TestPage' */ 'features/test-page/TestPage'),
);
const LinkPlayerPage = lazy(
    () => import(/* webpackChunkName: 'LinkPlayerPage' */ 'features/link-player/LinkPlayerPage'),
);
const TeamPage = lazy(() => import(/* webpackChunkName: 'TeamPage' */ 'features/team/TeamPage'));
const SubmitInvitationPage = lazy(
    () =>
        import(
            /* webpackChunkName: 'SubmitInvitationPage' */ 'features/invitation/SubmitInvitationPage'
        ),
);
const Auth = lazy(() => import(/* webpackChunkName: 'AuthPage' */ 'features/auth/AuthPage'));
const OrganizationPage = lazy(
    () =>
        import(/* webpackChunkName: 'OrganizationPage' */ 'features/organization/OrganizationPage'),
);
const MethodologyPage = lazy(
    () => import(/* webpackChunkName: 'MethodologyPage' */ 'features/methodology/MethodologyPage'),
);
const BackofficePage = lazy(
    () => import(/* webpackChunkName: 'BackofficePage' */ 'features/backoffice/BackofficePage'),
);

const TacticBoard = lazy(
    () => import(/* webpackChunkName: 'TacticBoard' */ 'features/tactic-board/TacticBoard'),
);

const ChallengesPage = lazy(
    () => import(/* webpackChunkName: 'ChallengesPage' */ 'features/challenges/ChallengesPage'),
);

const PlayerReadOnlyDashboard = lazy(
    () =>
        import(
            /* webpackChunkName: 'PlayerReadOnlyDashboard' */ 'features/player/dashboard/PlayerReadOnlyDashboard'
        ),
);

const TaskPage = lazy(
    () => import(/* webpackChunkName: 'TaskPage' */ 'features/team/conditions/TaskRedirectPage'),
);

const ScoutingPageWrapper = lazy(
    () => import(/* webpackChunkName: 'ScoutingPage' */ 'features/scouting/ScoutingPageWrapper'),
);

const Content: React.FC = () => {
    const authState = useSelector(authSelectors.authStateSelector);
    const { search, pathname } = useLocation();
    const isTryingToAuth = useCustomAuthToken();
    const isMobileApp = useMobileNavigation();

    const publicRoutes = useMemo(
        () => (
            <>
                <Route path="/" element={<MainPage />} />
                <Route path="/auth/*" element={<Auth />} />
                <Route path="/tactic-board" element={<TacticBoard />} />
                <Route path="/link-player/:playerId" element={<LinkPlayerPage />} />
            </>
        ),
        [],
    );

    if (authState === null || isTryingToAuth) {
        return <StylesSpinner showText />;
    }

    if (authState === AuthState.notAuthenticated) {
        const queryParams = qs.stringify({
            targetUrl: pathname,
            searchParams: search,
        });
        const redirectUrl = `/auth/login?${queryParams}`;

        return (
            <Suspense fallback={<StylesSpinner showText />}>
                <Routes>
                    {publicRoutes}
                    <Route path="*" element={<Navigate to={redirectUrl} />} />
                </Routes>
            </Suspense>
        );
    }

    if (authState === AuthState.notVerified) {
        return (
            <Suspense fallback={<StylesSpinner showText />}>
                <Routes>
                    {publicRoutes}
                    <Route path="*" element={<Navigate to="/auth/not-verified-email" />} />
                </Routes>
            </Suspense>
        );
    }

    return (
        <MainContent isMobileApp={isMobileApp}>
            <Suspense fallback={<StylesSpinner showText />}>
                <Routes>
                    {publicRoutes}
                    <Route path="/backoffice" element={<BackofficePage />} />
                    <Route path="/tasks/:taskId" element={<TaskPage />} />
                    <Route path="/account/profile" element={<ProfilePage />} />
                    <Route path="/organizations/:organizationId" element={<OrganizationPage />} />
                    <Route
                        path="/organizations/:organizationId/scouting/*"
                        element={<ScoutingPageWrapper />}
                    />
                    <Route path="/methodologies/:methodologyId/*" element={<MethodologyPage />} />
                    <Route
                        path="/accept-invitation/:invitationId"
                        element={<SubmitInvitationPage />}
                    />
                    <Route
                        path="/organizations/:organizationId/teams/:teamId/*"
                        element={<TeamPage />}
                    />
                    <Route path="/challenges/*" element={<ChallengesPage />} />
                    <Route path="/players/:playerId" element={<PlayerReadOnlyDashboard />} />
                    <Route path="/page-17" element={<TestPage />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </Suspense>
        </MainContent>
    );
};

export default Content;
