import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { NotificationsActiveIcon, NotificationsNoneIcon } from 'common/icons';
import { IconButton, Box, Typography } from 'common/components';
import NotificationsList from './NotificationsList';
import { userNotificationsListSelector } from 'entities/notifications/notifications.selectors';
import {
    notificationsListRequest,
    updateNotification,
} from 'entities/notifications/notifications.actions';
import { userDataSelector } from 'entities/users/users.selectors';
import Notification from 'entities/notifications/Notification';
import SlideDialogWithCloseBtn from '../SlideDialogWithCloseBtn';

export type NotificationsBtnProps = {
    isExpanded?: boolean;
    sx?: any;
};

const NotificationsBtn: React.FC<NotificationsBtnProps> = ({ sx, isExpanded, ...props }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(userDataSelector);
    const notificationsList = useSelector(userNotificationsListSelector);

    useEffect(() => {
        if (user) {
            dispatch(notificationsListRequest({ userId: user.id }));
        }
    }, [user]);

    const handleNotification = useCallback((notification: Notification) => {
        const { id, userId, ...notificationData } = notification;
        const { targetPage, seen } = notificationData;

        if (targetPage) {
            const targetLink = targetPage.replace('https://app.undersports.club', '');

            /* Skip redirects for event updates */
            if (!targetLink.startsWith('/notifications')) {
                navigate(targetLink);
            }
        }

        if (!seen) {
            dispatch(
                updateNotification({
                    notificationId: id,
                    userId,
                    notificationData: {
                        ...notificationData,
                        seen: true,
                    },
                }),
            );
        }
        setIsOpen(false);
    }, []);

    useEffect(() => {
        if (notificationsList.length > 0) {
            const lastNotification = notificationsList.reverse()[0];
            if (!lastNotification.seen) {
                handleNotification(lastNotification);
            }
        }
    }, [notificationsList.length, handleNotification]);

    if (notificationsList.length === 0) {
        return null;
    }

    const hasNotSeenNotifications =
        notificationsList.filter((notification) => !notification.seen).length > 0;
    const IconComponent = hasNotSeenNotifications ? NotificationsActiveIcon : NotificationsNoneIcon;

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
                <IconButton
                    sx={{ opacity: hasNotSeenNotifications ? 1 : 0.5 }}
                    onClick={() => setIsOpen(true)}
                    {...props}
                >
                    <IconComponent
                        sx={{
                            color: hasNotSeenNotifications ? 'orange' : 'darkgrey',
                        }}
                    />
                </IconButton>
                {isExpanded && <Typography sx={{ ml: 1 }}>{t('sidebar.notifications')}</Typography>}
            </Box>
            <SlideDialogWithCloseBtn isOpen={isOpen} onClose={() => setIsOpen(false)} noPaddings>
                <NotificationsList
                    notificationsList={notificationsList}
                    onSelect={handleNotification}
                />
            </SlideDialogWithCloseBtn>
        </>
    );
};

export default NotificationsBtn;
