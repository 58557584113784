import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as invitationsGateways from 'gateways/invitationsGateway';
import { AccessTarget, AccessTypes } from 'entities/access/Access';
import * as snackbarActions from 'common/snackbar/snackbar.actions';
import { requestUserAccessRecords } from 'entities/access/access.actions';
import { removeInvitationFromListAction } from 'entities/invitations/invitations.actions';
import invitationActionTypes from './invitationPage.actionTypes';
import * as invitationPageActions from './invitationPage.actions';
import { getLonglistUrl } from 'common/utils/urlUtils';

const linksMap = {
    [AccessTypes.methodology]: ({ methodologyId }: AccessTarget) =>
        `/methodologies/${methodologyId}`,
    [AccessTypes.team]: ({ teamId, organizationId }: AccessTarget) =>
        `/organizations/${organizationId}/teams/${teamId}/planning/month`,
    [AccessTypes.organization]: ({ organizationId }: AccessTarget) =>
        `/organizations/${organizationId}`,
    [AccessTypes.player]: () => '/',
    [AccessTypes.longlist]: ({ listId, organizationId }: AccessTarget) =>
        getLonglistUrl(organizationId!, listId!),
    [AccessTypes.shadowTeam]: ({ listId, organizationId }: AccessTarget) =>
        `/organizations/${organizationId}/scouting/shadowteams/${listId}`,
};

const getRedirectLink = ({
    accessType,
    target,
}: {
    accessType: AccessTypes;
    target: AccessTarget;
}) => linksMap[accessType](target);

export function* submitInvitationSaga({
    target,
    accessType,
    navigate,
    invitationId,
    targetName,
}: ReturnType<typeof invitationPageActions.submitInvitation>) {
    try {
        yield put(invitationPageActions.startSubmit());
        yield call([invitationsGateways, invitationsGateways.acceptInvitation], {
            target,
            accessType,
            invitationId,
            targetName,
        });
        yield put(requestUserAccessRecords());
        yield put(invitationPageActions.stopSubmit());
        yield put(removeInvitationFromListAction({ invitationId }));
        yield call(navigate, getRedirectLink({ accessType, target }));
    } catch (e: any) {
        yield put(invitationPageActions.stopSubmit());
        if (e.message === 'No invitation found') {
            yield put(
                snackbarActions.showWarning({
                    message:
                        'Seams like this invitation link were already used. 👈 Check the sidebar for your team',
                }),
            );
        } else {
            yield put(snackbarActions.showError({ message: e.message }));
        }
    }
}

function* invitationSagas() {
    yield all([takeLatest(invitationActionTypes.SUBMIT_INVITATION, submitInvitationSaga)]);
}

export default invitationSagas;
