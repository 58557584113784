import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccessTarget, AccessTypes } from './Access';
import { RootState } from 'store';
import { accessListForResourceSelector, userMethodologiesSelector } from './access.selectors';
import { requestAccessList } from './access.actions';
import { requestInvitationsList } from 'entities/invitations/invitations.actions';
import { targetInvitationsListSelector } from 'entities/invitations/invitations.selectors';
import Invitation from 'entities/invitations/Invitation';

export const useAccessRecords = ({
    accessType,
    target,
}: {
    accessType: AccessTypes;
    target: AccessTarget;
}) => {
    const dispatch = useDispatch();
    const accessList = useSelector((state: RootState) =>
        accessListForResourceSelector(state, { target }),
    );

    useEffect(() => {
        dispatch(requestAccessList({ target, accessType }));
    }, []);

    return accessList;
};

export const useInvitations = ({
    accessType,
    target,
}: {
    accessType: AccessTypes;
    target: AccessTarget;
}) => {
    const dispatch = useDispatch();
    const invitationsList = useSelector((state: RootState) =>
        targetInvitationsListSelector(state, { target }),
    );

    useEffect(() => {
        dispatch(requestInvitationsList({ target, accessType }));
    }, []);

    return invitationsList as Invitation[];
};

export const useUserMethodologies = () => {
    const userMethodologies = useSelector(userMethodologiesSelector);

    return userMethodologies;
};
