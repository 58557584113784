import _groupBy from 'lodash/groupBy';
import _isEqual from 'lodash/isEqual';
import Player from 'entities/players/Player';
import { getGameUrl, getSessionUrl } from 'common/utils/urlUtils';
import GeneralEvent from './GeneralEvent';
import Game, { PlayerStatus } from './Game';
import { getPlayerGameTime } from './games.utils';
import Session, { AttendanceStatus } from './Session';
import { getPlayerSessionTime } from './sessions.utils';
import { EventLocationDetails, EventPlayerInfo, EventTypes } from './Event';

export const playerToEventInfo = (player: Player | EventPlayerInfo): EventPlayerInfo => {
    const {
        id,
        lastName,
        firstName,
        enFirstName = '',
        enLastName = '',
        filePath,
        playerNumber,
        teams,
        userId,
        positions,
    } = player;
    return {
        id,
        lastName,
        firstName,
        enFirstName,
        enLastName,
        filePath,
        playerNumber,
        positions,
        teams,
        userId,
    };
};

export const mapEventToDatesStrings = (event: Game | Session) => {
    const start = event.start.toISOString();
    const end = event.end ? event.end.toISOString() : null;

    return {
        ...event,
        start,
        end,
    };
};

export const mapEventFromDatesStrings = (event: Game | Session) => {
    const start = new Date(event.start);
    const end = event.end ? new Date(event.end) : null;

    return {
        ...event,
        start,
        end,
    };
};

export const getPlayerLoadTime = (event: Game | Session, playerId: string) => {
    if (event.eventType === EventTypes.game) {
        const { substitutions, gameTime, lineup } = (event as Game).details;
        const lineupPosition = lineup[playerId];

        if (!lineupPosition) {
            return 0;
        }

        if (lineupPosition.time) {
            return Number(lineupPosition.time);
        }

        const isLineup = lineupPosition?.status === PlayerStatus.lineup;

        return getPlayerGameTime(substitutions, playerId, gameTime, isLineup, lineupPosition)
            .playerGameTime;
    }

    return getPlayerSessionTime(event as Session, playerId);
};

export const getEventPlayersDiff = ({
    eventPlayers,
    playersList,
    skipNumbers,
}: {
    eventPlayers: EventPlayerInfo[];
    playersList: Player[];
    skipNumbers?: boolean;
}) => {
    const eventPlayersIds = eventPlayers.map((player) => player.id);
    const teamPlayersIds = playersList.map((player) => player.id);
    const playersIdsToAdd = teamPlayersIds.filter(
        (playerId) => !eventPlayersIds.includes(playerId),
    );
    const playersIdsToRemove = eventPlayersIds.filter(
        (playerId) => !teamPlayersIds.includes(playerId),
    );

    const playersInfoMap = new Map<string, EventPlayerInfo>(
        eventPlayers.map((eventPlayerInfo) => [eventPlayerInfo.id, eventPlayerInfo]),
    );

    /* Add missing players to the map and override some players with current player info */
    playersList.forEach((player) => {
        playersInfoMap.set(player.id, playerToEventInfo(player));
    });

    const playersIdsToUpdate = eventPlayers
        .filter((eventPlayer) => {
            const currentPlayer = playersList.find((player) => player.id === eventPlayer.id);

            if (!currentPlayer) {
                return false;
            }

            const currentEventPlayer = playerToEventInfo(currentPlayer);

            if (skipNumbers) {
                /* To skip player number comparison.
                 * It is safe to mutate currentEventPlayer since it lives in the fileter callback only
                 */
                currentEventPlayer.playerNumber = eventPlayer.playerNumber;
            }

            return !_isEqual(eventPlayer, currentEventPlayer);
        })
        .map((player) => player.id);

    return {
        playersIdsToAdd,
        playersIdsToRemove,
        playersIdsToUpdate,
        playersInfoMap,
    };
};

export const getIsPlayerPresent = (sessionOrGame: Session | Game, playerId: string) => {
    const isSession = sessionOrGame.eventType === EventTypes.session;

    if (isSession) {
        return (sessionOrGame as Session).attendance[playerId]?.status === AttendanceStatus.present;
    }

    return [PlayerStatus.lineup, PlayerStatus.bench].includes(
        (sessionOrGame as Game).details.lineup[playerId]?.status,
    );
};

export const getEventLink = ({
    eventType,
    eventId,
    teamId,
    organizationId,
}: {
    eventType: EventTypes.game | EventTypes.session;
    eventId: string;
    teamId: string;
    organizationId: string;
}) => {
    if (eventType === EventTypes.session) {
        return getSessionUrl(organizationId, teamId, eventId);
    }

    return getGameUrl(organizationId, teamId, eventId);
};

export const createEventLocationDetails = (
    locationId: string | null = null,
    areaIds: string[] = [],
) => {
    return {
        locationId,
        areaIds,
        wholeLocation: areaIds?.length === 0 ? true : false,
    } as EventLocationDetails;
};

const isOverlappingDates = (
    eventA: Session | Game | GeneralEvent,
    eventB: Session | Game | GeneralEvent,
) => {
    return (
        Math.max(eventA.start.getTime(), eventB.start.getTime()) <
        Math.min(eventA.end.getTime(), eventB.end.getTime())
    );
};
export const eventsTimeOverlapAtLocations = (eventsList: (Session | Game | GeneralEvent)[]) => {
    const overlappingEventIds = new Set<string>();

    const eventsWithLocation = eventsList.filter(
        (event) => event.details.locationDetails.locationId,
    );
    const eventsByLocation = _groupBy(
        eventsWithLocation,
        (event) => event.details.locationDetails.locationId,
    );
    const clubLocationIds = Object.keys(eventsByLocation);

    clubLocationIds.forEach((locationId) => {
        const events = eventsByLocation[locationId];

        events.forEach((eventA, index) => {
            events.slice(index + 1).forEach((eventB) => {
                if (isOverlappingDates(eventA, eventB)) {
                    overlappingEventIds.add(eventA.id).add(eventB.id);
                }
            });
        });
    });

    return overlappingEventIds;
};
