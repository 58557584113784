import Player from 'entities/players/Player';
import Game, { GameData } from './Game';
import Session, { SessionData } from './Session';
import GeneralEvent, { GeneralEventData } from './GeneralEvent';
import DayLabel, { DayLabelData } from './DayLabel';

export type EventPlayerInfo = Pick<
    Player,
    | 'id'
    | 'lastName'
    | 'firstName'
    | 'enLastName'
    | 'enFirstName'
    | 'filePath'
    | 'playerNumber'
    | 'positions'
    | 'teams'
    | 'userId'
>;

export enum EventTypes {
    session = 'session',
    game = 'game',
    general = 'general',
    dayLabel = 'dayLabel',
}

export type EventLocationDetails = {
    locationId: string | null;
    areaIds: string[];
    wholeLocation: boolean;
};

type Event = Session | Game | DayLabel | GeneralEvent;

export type EventData = SessionData | GameData | DayLabelData | GeneralEventData;

export default Event;
