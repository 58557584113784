import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box } from 'common/components';
import { styled, SxProps } from '@mui/material';
import { AccessTarget } from 'entities/access/Access';
import CreateMethodologyBtn from 'features/methodology/CreateMethodologyBtn';
import { organizationDataRequest } from 'entities/organizations/organizations.actions';
import { ListIcon, OrganizationIcon, PlayerIcon, SchoolIcon, TeamIcon } from 'common/icons';
import AccessGroup from './AccessGroup';
import { useUserLinks } from './userLinks.utils';

const Root = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));

type UserLinksProps = {
    hideIfEmpty?: boolean;
    allowDelete?: boolean;
    sx?: SxProps;
    onSelect?: VoidFunction;
};

const UserLinks: React.FC<UserLinksProps> = ({ hideIfEmpty, allowDelete, onSelect, ...rest }) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const { organizationsList, teamsList, methodologiesList, playersList, longlistsList } =
        useUserLinks();
    const dispatch = useDispatch();

    useEffect(() => {
        organizationsList.forEach((record) => {
            dispatch(organizationDataRequest({ organizationId: record.target.organizationId! }));
        });
    }, [organizationsList.length]);

    const checkOrganizationMatch = useCallback(
        (target: AccessTarget) =>
            Boolean(target.organizationId && pathname.includes(target.organizationId)),
        [pathname],
    );

    const checkTeamMatch = useCallback(
        (target: AccessTarget) =>
            Boolean(
                target.teamId &&
                    pathname.includes(`${target.organizationId}/teams/${target.teamId}`),
            ),
        [pathname],
    );

    const checkPlayerMatch = useCallback(
        (target: AccessTarget) =>
            Boolean(target.playerId && pathname.includes(`players/${target.playerId}`)),
        [pathname],
    );

    const checkLonglistMatch = useCallback(
        (target: AccessTarget) =>
            Boolean(
                target.listId &&
                    pathname.includes(
                        `${target.organizationId}/scouting/longlists/${target.listId}`,
                    ),
            ),
        [pathname],
    );

    const checkMethodologyMatch = useCallback(
        (target: AccessTarget) => pathname.includes(`methodologies/${target.methodologyId}`),
        [pathname],
    );

    const isHidden =
        hideIfEmpty &&
        organizationsList.length === 0 &&
        teamsList.length === 0 &&
        methodologiesList.length === 0 &&
        playersList.length === 0 &&
        longlistsList.length === 0;

    if (isHidden) {
        return null;
    }

    return (
        <Root {...rest}>
            <AccessGroup
                accessList={organizationsList}
                title={t('userLinks.clubs')}
                shouldHideIfEmpty
                allowDelete={allowDelete}
                IconComponent={OrganizationIcon}
                checkMatch={checkOrganizationMatch}
                onSelect={onSelect}
            />
            <AccessGroup
                accessList={teamsList}
                title={t('userLinks.teams')}
                shouldHideIfEmpty
                allowDelete={allowDelete}
                IconComponent={TeamIcon}
                checkMatch={checkTeamMatch}
                onSelect={onSelect}
            />
            <AccessGroup
                accessList={methodologiesList}
                title={t('userLinks.methodologies')}
                SideComponent={<CreateMethodologyBtn sx={{ ml: 1 }} />}
                emptyPlaceholder="No methodologies"
                IconComponent={SchoolIcon}
                allowDelete={allowDelete}
                checkMatch={checkMethodologyMatch}
                onSelect={onSelect}
            />
            <AccessGroup
                accessList={playersList}
                title={t('userLinks.players')}
                shouldHideIfEmpty
                allowDelete={allowDelete}
                IconComponent={PlayerIcon}
                checkMatch={checkPlayerMatch}
            />
            <AccessGroup
                accessList={longlistsList}
                title={t('userLinks.longlists')}
                shouldHideIfEmpty
                allowDelete={allowDelete}
                IconComponent={ListIcon}
                checkMatch={checkLonglistMatch}
                onSelect={onSelect}
            />
        </Root>
    );
};

export default UserLinks;
