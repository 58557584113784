import { RootState } from 'store';
import { isBefore } from 'date-fns';
import { createSelector } from 'reselect';
import Player, { PlayerSkills, PlayerStatus } from './Player';
import { PlayerUpdate, PlayerUpdateType } from './PlayerUpdates';

export const playersHashSelector = (state: RootState) => state.entities.players.playersHash;

export const fetchedPlayersIdsSelector = createSelector(playersHashSelector, (playersHash) => {
    return Object.keys(playersHash);
});

export const playersSkillsHashSelector = (state: RootState) =>
    state.entities.players.playersSkillsHash;

export const playersUpdatesHashSelector = (state: RootState) =>
    state.entities.players.playersUpdatesHash;

export const requestedTeamsSelector = (state: RootState) => state.entities.players.requestedTeams;

export const playerSkillsSelector = createSelector(
    playersSkillsHashSelector,
    (_: any, { playerId }: { playerId: string }) => playerId,
    (playersSkillsHash, playerId) => playersSkillsHash[playerId] as PlayerSkills | undefined,
);

export const playersListSelector = createSelector(playersHashSelector, (playersHash) => {
    return Object.values(playersHash).filter((player) => player.status === PlayerStatus.active);
});

export const playersUpdatesListSelector = createSelector(
    playersUpdatesHashSelector,
    (playersHash) => {
        return Object.values(playersHash) as PlayerUpdate[];
    },
);

export const playerUpdatesListSelector = createSelector(
    playersUpdatesListSelector,
    (_: any, { playerId }: { playerId: string }) => playerId,
    (_: any, { organizationId }: { organizationId: string }) => organizationId,
    (_: any, { updatesType }: { updatesType?: PlayerUpdateType }) => updatesType,
    (playerUpdatesList, playerId, organizationId, updatesType) => {
        const result = playerUpdatesList.filter(
            (playerUpdateRecord) =>
                playerUpdateRecord.playerId === playerId &&
                playerUpdateRecord.organizationId === organizationId,
        );
        result.sort((a, b) => (isBefore(a.date, b.date) ? 1 : -1));

        if (!updatesType) {
            return result;
        }

        return result.filter((record) => record.details.type === updatesType);
    },
);

export const playerDataSelector = (state: RootState, { playerId }: { playerId: string }) => {
    return state.entities.players.playersHash[playerId];
};

export const removedPlayersSelector = (state: RootState) => {
    return state.entities.players.removedPlayers;
};

export const agentPlayersListSelector = createSelector(
    playersListSelector,
    (_: any, { agentId }: { agentId: string }) => agentId,
    (playersList, agentId) => {
        return playersList.filter((player) => player.agent?.id === agentId);
    },
);

export const scoutingPlayersListSelector = createSelector(
    playersListSelector,
    (_: any, { organizationId }: { organizationId: string }) => organizationId,
    (playersList: Player[], organizationId) => {
        const result = playersList.filter(
            (player: Player) => player.teams.scouting.organizationId === organizationId,
        );

        result.sort((a: Player, b: Player) => Number(a.playerNumber) - Number(b.playerNumber));

        return result;
    },
);

export const teamPlayersListSelector = createSelector(
    playersListSelector,
    (_: any, { teamId }: { teamId: string }) => teamId,
    (_: any, { organizationId }: { organizationId?: string }) => organizationId,
    (playersList: Player[], teamId, organizationId) => {
        let result = playersList.filter(
            (player: Player) => player.teams && player.teams[teamId]?.active,
        );

        /* organizationId is needed for scouting where team id is always 'scouting' */
        if (organizationId) {
            result = result.filter(
                (player: Player) => player.organizations[organizationId]?.active,
            );
        }

        result.sort((a: Player, b: Player) => Number(a.playerNumber) - Number(b.playerNumber));

        return result;
    },
);

export const organizationPlayersSelector = createSelector(
    playersListSelector,
    (_: any, { organizationId }: { organizationId: string }) => organizationId,
    (playersList: Player[], organizationId) => {
        const result = playersList.filter(
            (player: Player) =>
                player.organizations && player.organizations[organizationId]?.active,
        );
        result.sort((a: Player, b: Player) => Number(a.playerNumber) - Number(b.playerNumber));

        return result;
    },
);

export const playersByIdsSelector = createSelector(
    playersHashSelector,
    (_: any, { playersIds }: { playersIds: string[] }) => playersIds,
    (playersHash, playersIds) => {
        return playersIds
            .map((playerId) => {
                return playersHash[playerId];
            })
            .filter(Boolean)
            .filter((player) => player.status === PlayerStatus.active) as Player[];
    },
);

export const playersByClubIdSelector = createSelector(
    playersListSelector,
    (_: any, { clubId }: { clubId: string }) => clubId,
    (playersList, clubId) => {
        return playersList.filter((player) => player.club?.clubId === clubId) as Player[];
    },
);
