import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'common/components';
import { styled, SxProps } from '@mui/material/styles';
import { appConfigSelector } from 'common/config/config.selectors';
import GetMethodologyBtn from 'features/methodology/GetMethodologyBtn';
import { useUserMethodologies } from 'entities/access/access.hooks';

const Root = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.custom.background.light,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
}));

type DemoMethodologyBoxProps = {
    sx?: SxProps;
};

const DemoMethodologyBox: React.FC<DemoMethodologyBoxProps> = ({ ...rest }) => {
    const { t } = useTranslation();
    const appConfig = useSelector(appConfigSelector);
    const methodologiesList = useUserMethodologies();

    const publicMethodologyId = appConfig?.publicMethodology;
    const canGetMethodology =
        publicMethodologyId &&
        !methodologiesList.find(({ target }) => target.methodologyId === publicMethodologyId);

    if (!canGetMethodology) {
        return null;
    }

    return (
        <Root {...rest}>
            <Typography variant="body2" sx={{ mb: 1 }}>
                {t('session.addDrillDialog.noMethodology.demoMethodology.description')}
            </Typography>
            <GetMethodologyBtn />
        </Root>
    );
};

export default DemoMethodologyBox;
