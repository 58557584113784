import React, { useCallback, useEffect } from 'react';
import qs from 'qs';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PersonAddIcon } from 'common/icons';
import { styled, SxProps } from '@mui/material';
import { Button, Callout } from 'common/components';
import Invitation from 'entities/invitations/Invitation';
import { fetchUserInvitations } from 'entities/invitations/invitations.actions';
import { CardBody, CardHeader, CardRoot } from 'common/entity-updates/CommonComponents';
import {
    playerToLinkSelector,
    userInvitationsListSelector,
} from 'entities/invitations/invitations.selectors';

const Root = styled(CardRoot)(() => ({
    width: '100%',
    maxWidth: 400,
}));

const InvitationBtn = styled(Button)(() => ({
    alignSelf: 'stretch',
}));

type InvitationsListProps = {
    sx?: SxProps;
};

const InvitationsList: React.FC<InvitationsListProps> = ({ ...rest }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const playerToLink = useSelector(playerToLinkSelector);
    const invitationsList = useSelector(userInvitationsListSelector);

    useEffect(() => {
        dispatch(fetchUserInvitations());
    }, []);

    const handleLinkPlayer = useCallback((playerId: string) => {
        navigate(`/link-player/${playerId}`);
    }, []);

    const handleAcceptInvitation = useCallback((invitation: Invitation) => {
        const { target, accessType, targetName, id } = invitation;
        const params = { target, accessType, targetName };
        const query = qs.stringify(params);
        navigate(`/accept-invitation/${id}?${query}`);
    }, []);

    if (!playerToLink && invitationsList.length === 0) {
        return null;
    }

    return (
        <Root {...rest}>
            <CardHeader
                title={t('invitationsList.title')}
                IconComponent={
                    <PersonAddIcon
                        sx={{
                            width: 16,
                            height: 16,
                            mr: 1,
                            color: (theme) => theme.palette.primary.main,
                        }}
                    />
                }
            />
            <CardBody sx={{ flexDirection: 'column' }}>
                <Callout text={t('invitationsList.description')} sx={{ m: 1, width: 'auto' }} />
                {playerToLink && (
                    <InvitationBtn
                        sx={{ mb: 1, mx: 1 }}
                        size="small"
                        variant="contained"
                        color="primary"
                        startIcon={<PersonAddIcon />}
                        onClick={() => handleLinkPlayer(playerToLink)}
                    >
                        {t('invitationsList.linkPlayerProfileBtn')}
                    </InvitationBtn>
                )}
                {invitationsList.map((invitation) => (
                    <InvitationBtn
                        key={invitation.id}
                        sx={{ mb: 1, mx: 1 }}
                        size="small"
                        variant="contained"
                        color="primary"
                        startIcon={<PersonAddIcon />}
                        onClick={() => handleAcceptInvitation(invitation)}
                    >
                        {t('invitationsList.joinResource', { targetName: invitation.targetName })}
                    </InvitationBtn>
                ))}
            </CardBody>
        </Root>
    );
};

export default InvitationsList;
