import React, { useCallback } from 'react';
import { Button } from 'common/components';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { MIN_PLAYERS_COUNT } from './assistant.constants';
// import HelpLink from './HelpLink';
import AssistantBase from './AssistantBase';

type NoPlayersProps = {
    teamId: string;
    organizationId: string;
    playersCount: number;
    onClose: VoidFunction;
};

const NoPlayers: React.FC<NoPlayersProps> = ({ organizationId, teamId, playersCount, onClose }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        onClose();
        navigate(`/organizations/${organizationId}/teams/${teamId}/players`);
    }, [teamId, organizationId, navigate]);

    if (playersCount >= MIN_PLAYERS_COUNT) {
        return null;
    }

    const notEnoughPlayersText = t('assistant.noPlayers.description', {
        minCount: MIN_PLAYERS_COUNT,
        count: playersCount,
    });
    const noPlayersText = t('assistant.noPlayers.zeroPlayers', { minCount: MIN_PLAYERS_COUNT });
    const descriptionText = playersCount === 0 ? noPlayersText : notEnoughPlayersText;

    return (
        <AssistantBase description={descriptionText} icon="🤝">
            <Button onClick={handleClose} variant="contained" color="primary" size="small">
                {t('assistant.noPlayers.addPlayersBtn')}
            </Button>
            {/* <HelpLink href="https://undersports.club/ru/create-player">
                {t('assistant.noPlayers.helpBtn')}
            </HelpLink> */}
        </AssistantBase>
    );
};

export default NoPlayers;
