import { useCallback, useEffect, useMemo, useState } from 'react';
import { RootState } from 'store';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import _debounce from 'lodash/debounce';
import { Permissions } from 'entities/access/Permissions';
import { TeamPageParams } from 'features/team/TeamPageTypes';
import { useTeamPermissions } from 'entities/access/access.utils';
import { useScoutingAccess } from 'features/scouting/scouting.utils';
import Player, { PlayerSkills } from './Player';
import { PlayerUpdateType } from './PlayerUpdates';
import {
    playerDataRequest,
    playerSkillsRequest,
    requestAgentPlayersList,
    requestPlayerUpdatesList,
    requestTeamPlayersList,
    scoutingClubPlayersRequest,
    updatePlayerSkills,
} from './players.actions';
import {
    agentPlayersListSelector,
    playerDataSelector,
    playersByClubIdSelector,
    playersByIdsSelector,
    playerSkillsSelector,
    playerUpdatesListSelector,
    teamPlayersListSelector,
} from './players.selectors';

export const usePlayer = ({
    playerId,
    organizationId,
    skipFetching,
}: {
    playerId?: string | null;
    organizationId: string;
    skipFetching?: boolean;
}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const player = useSelector((state: RootState) =>
        playerId ? playerDataSelector(state, { playerId }) : undefined,
    ) as Player | undefined;

    useEffect(() => {
        if (!player && playerId && playerId !== 'new' && !skipFetching) {
            setIsLoading(true);
            dispatch(
                playerDataRequest({
                    playerId,
                    organizationId,
                    onSuccess: () => setIsLoading(false),
                    onFail: () => setIsLoading(false),
                }),
            );
        }
    }, [player, skipFetching]);

    return { player, isLoading };
};

export const usePlayerWithAccess = (playerId: string) => {
    const { teamId, organizationId } = useParams() as TeamPageParams;
    const hasPlayerPermissions = useTeamPermissions({ organizationId, teamId });
    const { player, isLoading } = usePlayer({ playerId, organizationId });

    const isTeamPlayer =
        player?.organizations?.[organizationId]?.active && player?.teams?.[teamId]?.active;
    const hasPermission = isTeamPlayer && hasPlayerPermissions(Permissions.canReadPlayerProfile);

    return { player: hasPermission ? player : null, isLoading, hasPermission };
};

export const usePlayerWithScoutingAccess = ({
    playerId,
    organizationId,
}: {
    playerId: string;
    organizationId: string;
}) => {
    const { canReadPlayerGeneralInfo } = useScoutingAccess({
        organizationId,
    });
    const { player } = usePlayer({ playerId, organizationId });

    if (!canReadPlayerGeneralInfo) return undefined;

    return player;
};

export const useTeamPlayers = ({
    organizationId,
    teamId,
}: {
    organizationId: string;
    teamId: string;
}) => {
    const dispatch = useDispatch();

    const playersList = useSelector((state: RootState) =>
        teamPlayersListSelector(state, {
            teamId,
            organizationId,
        }),
    );

    useEffect(() => {
        dispatch(requestTeamPlayersList({ teamId, organizationId }));
    }, [organizationId, teamId, dispatch]);

    return playersList;
};

export const useAgentPlayers = ({
    organizationId,
    agentId,
}: {
    organizationId: string;
    agentId: string | null;
}) => {
    const dispatch = useDispatch();

    const playersList = useSelector((state: RootState) =>
        agentId ? agentPlayersListSelector(state, { agentId }) : [],
    );

    useEffect(() => {
        if (agentId) {
            dispatch(requestAgentPlayersList({ agentId, organizationId }));
        }
    }, [organizationId, agentId, dispatch]);

    return playersList;
};

export const useClubPlayers = ({
    organizationId,
    clubId,
}: {
    organizationId: string;
    clubId: string | null;
}) => {
    const dispatch = useDispatch();

    const playersList = useSelector((state: RootState) =>
        clubId ? playersByClubIdSelector(state, { clubId }) : [],
    );

    useEffect(() => {
        if (clubId) {
            dispatch(scoutingClubPlayersRequest({ clubId, organizationId }));
        }
    }, [organizationId, clubId, dispatch]);

    return playersList;
};

export const usePlayerSkills = (playerId: string) => {
    const dispatch = useDispatch();

    const skills = useSelector((state: RootState) => playerSkillsSelector(state, { playerId }));

    useEffect(() => {
        if (!skills) {
            dispatch(playerSkillsRequest(playerId));
        }
    }, [dispatch]);

    const onUpdate = useCallback(
        (newSkills: PlayerSkills) => {
            dispatch(updatePlayerSkills({ playerId, skills: newSkills }));
        },
        [playerId],
    );

    return { skills, onUpdate };
};

export const usePlayerUpdates = ({
    organizationId,
    playerId,
    updatesType,
    skipRequest,
}: {
    organizationId: string;
    playerId: string;
    updatesType?: PlayerUpdateType;
    skipRequest?: boolean;
}) => {
    const dispatch = useDispatch();

    const playerUpdatesList = useSelector((state: RootState) =>
        playerUpdatesListSelector(state, { playerId, organizationId, updatesType }),
    );

    useEffect(() => {
        if (!skipRequest) {
            dispatch(requestPlayerUpdatesList({ playerId, organizationId, updatesType }));
        }
    }, [playerId, organizationId, skipRequest, dispatch]);

    return playerUpdatesList;
};

/* TODO: remove same method from scouting.utils.ts or from here */
export const usePlayersByIds = ({
    playersIds,
    organizationId,
    refreshPredicate,
}: {
    playersIds: string[];
    organizationId: string;
    refreshPredicate?: any;
}) => {
    const dispatch = useDispatch();
    const playersList = useSelector((state: RootState) =>
        playersByIdsSelector(state, { playersIds }),
    );

    useEffect(() => {
        const playersIdsWithData = playersList.map((player) => player.id);
        playersIds.forEach((playerId) => {
            if (!playersIdsWithData.includes(playerId)) {
                dispatch(playerDataRequest({ playerId, organizationId }));
            }
        });
    }, [refreshPredicate, organizationId, dispatch]);

    return playersList;
};

export const useFilteredPlayers = ({ playersList }: { playersList: Player[] }) => {
    const [searchText, setSearchText] = useState<string>('');
    const [debouncedText, setDebouncedText] = useState<string>('');

    const handleFilterText = useMemo(() => {
        return _debounce(setDebouncedText, 500);
    }, []);

    const handleChange = useCallback(
        (text: string) => {
            setSearchText(text);
            handleFilterText(text);
        },
        [setSearchText, handleFilterText],
    );

    const filteredPlayers = useMemo(
        () =>
            playersList.filter((player) => {
                const { firstName, lastName, enLastName, enFirstName } = player;
                return [firstName, lastName, enLastName, enFirstName]
                    .join('')
                    .toLowerCase()
                    .includes(debouncedText);
            }),
        [debouncedText, playersList],
    );

    return { filteredPlayers, onTextChange: handleChange, searchText };
};
