import { toastr } from 'react-redux-toastr';
import * as playersGateway from 'gateways/playersGateway';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { PlayerUpdate } from './PlayerUpdates';
import actionTypes from './players.actionTypes';
import * as playersActions from './players.actions';
import * as playersSelectors from './players.selectors';
import Player, { PlayerData, PlayerSkills, PlayerStatus } from './Player';

export function* scoutingRecentPlayersRequestSaga({
    organizationId,
    onFinish,
}: ReturnType<typeof playersActions.scoutingRecentPlayersRequest>) {
    try {
        const playersList: Player[] = yield call(playersGateway.fetchScoutingRecentPlayersList, {
            organizationId,
        });

        yield put(playersActions.playersListReceived(playersList));
    } catch (e) {
        console.error(e);
        yield call(toastr.error, 'Something went wrong', 'Not able to load players');
    }

    yield call(onFinish);
}

export function* scoutingNoRefPlayersRequestSaga({
    organizationId,
    onFinish,
}: ReturnType<typeof playersActions.scoutingNoRefPlayersRequest>) {
    try {
        const playersList: Player[] = yield call(playersGateway.fetchScoutingNoRefPlayersList, {
            organizationId,
        });

        yield put(playersActions.playersListReceived(playersList));
    } catch (e) {
        console.error(e);
        yield call(toastr.error, 'Something went wrong', 'Not able to load players');
    }

    yield call(onFinish);
}

export function* scoutingClubPlayersRequestSaga({
    clubId,
    organizationId,
}: ReturnType<typeof playersActions.scoutingClubPlayersRequest>) {
    try {
        const playersList: Player[] = yield call(playersGateway.fetchScoutingClubPlayersList, {
            clubId,
            organizationId,
        });

        yield put(playersActions.playersListReceived(playersList));
    } catch (e) {
        console.error(e);
        yield call(toastr.error, 'Something went wrong', 'Not able to load players');
    }
}

export function* fetchTeamPlayersListSaga({
    teamId,
    organizationId,
}: ReturnType<typeof playersActions.requestTeamPlayersList>) {
    const requestedTeams: string[] = yield select(playersSelectors.requestedTeamsSelector);

    try {
        if (!requestedTeams.includes(teamId)) {
            const playersList: Player[] = yield call(
                [playersGateway, playersGateway.fetchTeamPlayersList],
                { teamId, organizationId },
            );

            yield put(playersActions.playersListReceived(playersList));
            yield put(playersActions.teamPlayersRequested(teamId));
        }
    } catch (e) {
        yield put(playersActions.playersListLoadError());
    }
}

export function* fetchAgentPlayersListSaga({
    agentId,
    organizationId,
}: ReturnType<typeof playersActions.requestAgentPlayersList>) {
    try {
        const playersList: Player[] = yield call(
            [playersGateway, playersGateway.fetchAgentPlayersList],
            { agentId, organizationId },
        );
        yield put(playersActions.playersListReceived(playersList));
    } catch (e) {
        console.error(e);
    }
}

export function* fetchPlayerUpdatesListSaga({
    playerId,
    organizationId,
    updatesType,
}: ReturnType<typeof playersActions.requestPlayerUpdatesList>) {
    try {
        const playerUpdatesList: PlayerUpdate[] = yield call(
            [playersGateway, playersGateway.fetchPlayerUpdatesList],
            { playerId, organizationId, updatesType },
        );
        yield put(playersActions.playerUpdatesListReceived({ playerUpdatesList }));
    } catch (e) {
        console.error(e);
    }
}

export function* fetchPlayerDataSaga({
    playerId,
    organizationId,
    onSuccess,
    onFail,
}: ReturnType<typeof playersActions.playerDataRequest>) {
    try {
        const playerData: Player = yield call([playersGateway, playersGateway.fetchPlayerById], {
            playerId,
            organizationId,
        });
        if (playerData?.status === PlayerStatus.active) {
            yield put(playersActions.playerDataReceived(playerData));
        }

        if (playerData?.status === PlayerStatus.deleted) {
            yield put(playersActions.setRemovedPlayer({ playerId }));
        }

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* fetchPlayersByIdsSaga({
    playersIds,
    organizationId,
    onSuccess,
    onFail,
}: ReturnType<typeof playersActions.playersByIdsRequest>) {
    try {
        const players: Player[] = yield call([playersGateway, playersGateway.fetchPlayersByIds], {
            playersIds,
            organizationId,
        });

        yield put(playersActions.playersListReceived(players));
        yield call(onSuccess);
    } catch (e) {
        yield call(onFail);
        console.error(e);
    }
}

export function* fetchScoutingListPlayersByIdsSaga({
    playersIds,
    organizationId,
    listId,
    onSuccess,
    onFail,
}: ReturnType<typeof playersActions.scoutingListPlayersByIdsRequest>) {
    try {
        const players: Player[] = yield call(
            [playersGateway, playersGateway.fetchScoutingListPlayersByIds],
            {
                playersIds,
                organizationId,
                listId,
            },
        );

        yield put(playersActions.playersListReceived(players));
        yield call(onSuccess);
    } catch (e) {
        yield call(onFail);
        console.error(e);
    }
}

export function* fetchPlayerSkillsSaga({
    playerId,
}: ReturnType<typeof playersActions.playerSkillsRequest>) {
    try {
        const skills: PlayerSkills = yield call(playersGateway.fetchPlayerSkills, playerId);

        if (skills) {
            yield put(playersActions.playerSkillsReceived({ playerId, skills }));
        }
    } catch (e) {
        console.error(e);
    }
}

export function* updatePlayerSkillsSaga({
    playerId,
    skills,
}: ReturnType<typeof playersActions.updatePlayerSkills>) {
    try {
        yield call(playersGateway.updatePlayerSkills, {
            playerId,
            skills,
        });

        yield put(playersActions.playerSkillsRequest(playerId));
    } catch (e) {
        console.error(e);
    }
}

export function* createPlayerSaga({
    playerData,
    organizationId,
}: {
    playerData: PlayerData;
    organizationId: string;
}) {
    const playerId: string = yield call([playersGateway, playersGateway.createPlayer], {
        playerData,
    });
    yield put(playersActions.playerDataRequest({ playerId, organizationId }));
    return playerId;
}

export function* editPlayerInfoSaga({
    playerId,
    organizationId,
    playerData,
    onSuccess,
}: ReturnType<typeof playersActions.updatePlayer>) {
    yield call([playersGateway, playersGateway.editPlayer], {
        playerId,
        playerData,
    });
    yield put(playersActions.playerDataRequest({ playerId, organizationId }));

    if (onSuccess) {
        yield call(onSuccess);
    }
}

export function* removePlayerSaga({
    playerId,
    onSuccess,
}: ReturnType<typeof playersActions.removePlayer>) {
    try {
        yield call([playersGateway, playersGateway.removePlayer], {
            playerId,
        });
        yield put(playersActions.removePlayerFromLocalState({ playerId }));
        yield put(playersActions.setRemovedPlayer({ playerId }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);
    }
}

function* playersSagas() {
    yield all([
        takeLatest(actionTypes.SCOUTING_RECENT_PLAYERS_REQUEST, scoutingRecentPlayersRequestSaga),
        takeLatest(actionTypes.SCOUTING_NO_REF_PLAYERS_REQUEST, scoutingNoRefPlayersRequestSaga),
        takeLatest(actionTypes.SCOUTING_CLUB_PLAYERS_REQUESTED, scoutingClubPlayersRequestSaga),
        takeLatest(actionTypes.TEAM_PLAYERS_LIST_REQUEST, fetchTeamPlayersListSaga),
        takeLatest(actionTypes.AGENT_PLAYERS_LIST_REQUEST, fetchAgentPlayersListSaga),
        takeLatest(actionTypes.PLAYERS_UPDATES_LIST_REQUEST, fetchPlayerUpdatesListSaga),
        takeEvery(actionTypes.PLAYER_DATA_REQUEST, fetchPlayerDataSaga),
        takeEvery(actionTypes.PLAYERS_BY_IDS_REQUEST, fetchPlayersByIdsSaga),
        takeEvery(
            actionTypes.SCOUTING_LIST_PLAYERS_BY_IDS_REQUEST,
            fetchScoutingListPlayersByIdsSaga,
        ),
        takeLatest(actionTypes.UPDATE_PLAYER_DATA, editPlayerInfoSaga),
        takeLatest(actionTypes.REMOVE_PLAYER, removePlayerSaga),
        takeLatest(actionTypes.PLAYERS_SKILLS_REQUEST, fetchPlayerSkillsSaga),
        takeLatest(actionTypes.UPDATE_PLAYERS_SKILLS, updatePlayerSkillsSaga),
    ]);
}

export default playersSagas;
